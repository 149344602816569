import { DEXCommonExchangeVersion } from "src/modules/bots";
import {
  GetChainsMetaResponse,
  GetSupportedChainsResponse,
  GetSupportedExchangesResponse,
} from "./index";

export const SUPPORTED_CHAINS_MOCK: GetSupportedChainsResponse = {
  chains: [
    {
      scanner: "https://etherscan.io",
      symb: "ETH",
      chain_name: "Ethereum",
      dexscreener_alias: "ethereum",
    },
    {
      scanner: "https://bscscan.com",
      symb: "BNB",
      chain_name: "BSC",
      dexscreener_alias: "bsc",
    },
    {
      scanner: "https://solscan.io",
      symb: "SOL",
      chain_name: "Solana",
      dexscreener_alias: "solana",
    },
  ],
};

export const SUPPORTED_EXCHANGES_MOCK: GetSupportedExchangesResponse = {
  Ethereum: {
    UniswapV3: {
      dex_version: DEXCommonExchangeVersion.V3,
      pool_percent: [0, 100, 500],
    },
    SushiswapV3: {
      dex_version: DEXCommonExchangeVersion.V3,
      pool_percent: [0, 100],
    },
    UniswapV2: {
      dex_version: DEXCommonExchangeVersion.V2,
      pool_percent: [0],
    },
    SushiswapV2: {
      dex_version: DEXCommonExchangeVersion.V2,
      pool_percent: [0],
    },
  },
  BSC: {
    PancakeSwapV3: {
      dex_version: DEXCommonExchangeVersion.V3,
      pool_percent: [0, 100, 500],
    },
    PancakeSwapV2: {
      dex_version: DEXCommonExchangeVersion.V2,
      pool_percent: [0],
    },
  },
};

const MOCK_PICK =
  '<svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" fill="none" viewBox="0 0 128 128"><path fill="#f0b90b" d="M64 128c35.346 0 64-28.654 64-64S99.346 0 64 0 0 28.654 0 64s28.654 64 64 64"></path><path fill="#fff" d="M44.579 56.021 64 36.6l19.43 19.429 11.3-11.3L64 14 33.28 44.72l11.3 11.3zM14 64l11.3-11.302 11.3 11.3-11.3 11.3zm30.579 7.98L64 91.4l19.43-19.429 11.306 11.294-.006.006L64 114 33.28 83.28l-.016-.016L44.58 71.979zM91.4 64.005l11.3-11.3 11.3 11.3-11.3 11.3zm-15.939-.011h.005L64 52.528 55.525 61l-.974.974-2.008 2.008-.016.016.016.016L64 75.472l11.466-11.466.006-.006z"></path></svg>';

export const CHAINS_META_MOCK: GetChainsMetaResponse = [
  {
    chain: "Ethereum",
    abbr: "ETH",
    colour: "#627EEA",
    pic: MOCK_PICK,
  },
  {
    chain: "BSC",
    abbr: "BNB",
    colour: "#F0B90B",
    pic: MOCK_PICK,
  },
  {
    chain: "Solana",
    abbr: "SOL",
    colour: "#FF0000",
    pic: MOCK_PICK,
  },
];
