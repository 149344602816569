import { makeAutoObservable } from "mobx";
import { logError } from "src/helpers/network/logger";
import { TradeToken } from "src/state/DEXV2/shared/TradeToken";
import { AbstractToken } from "src/state/shared/DEX/Swap/AbstractToken";
import { IBotTickersProvider, ITradePairProvider } from "src/state/shared/DEX/providers/types";
import { TradePair } from "../../shared/DEX/entities/TradePair";

interface IDEXNETradePairParams {
  tickersProvider: IBotTickersProvider;
}

/** Provider returning static 18 decimals tokens since no tokens info is available on dex ne */
export class DEXNETradePairProviderStore implements ITradePairProvider {
  private _tradePair: TradePair | null = null;

  private _tickersProvider: IBotTickersProvider;

  constructor({ tickersProvider }: IDEXNETradePairParams) {
    makeAutoObservable(this);
    this._tickersProvider = tickersProvider;
  }

  private _setTradePair = (base: TradeToken, quote: TradeToken) => {
    this._tradePair = new TradePair(base, quote);
  };

  get tradePair() {
    return this._tradePair;
  }

  private get _tickers() {
    return this._tickersProvider.tickers ?? { base: "BASE", quote: "QUOTE" };
  }

  getTradePair = async () => {
    try {
      const { base, quote } = this._tickers;

      const baseToken = new AbstractToken({
        name: "Base Token",
        symbol: base,
      });

      const quoteToken = new AbstractToken({
        name: "Quote Token",
        symbol: quote,
      });

      this._setTradePair(
        TradeToken.fromToken(baseToken, "base"),
        TradeToken.fromToken(quoteToken, "quote")
      );
    } catch (err) {
      logError(err);
    }
  };

  destroy = () => {};
}
