import { ChainsMetaResponse, GetChainsMetaResponse } from "src/api/bots/DEX_NE/chains";
import { Mapper } from "src/helpers/utils";
import { IChainMeta } from "src/modules/chain";
import { ChainMetaMap } from "./types";

const chainMetaRespToChainMeta: Mapper<ChainsMetaResponse, IChainMeta> = ({
  abbr,
  colour,
  pic,
}) => ({
  abbr,
  color: colour,
  icon: pic,
});

export const chainsMetaRespToChainMeta: Mapper<GetChainsMetaResponse, ChainMetaMap> = (resp) =>
  resp.reduce<ChainMetaMap>((acc, chain) => {
    const chainConfig = chainMetaRespToChainMeta(chain);
    acc[chain.chain] = chainConfig;
    return acc;
  }, {});
