import { Account } from "../userManager";

export type AccountApi = Pick<Account, "name" | "uuid">;

// base account name + arbitrary number of indexed names
type OrdinalAccountName<T extends string> = T | `${T}${number}`;

// base liquidity account names
export const LIQUIDITY_ACCOUNT_NAMES = ["info", "spread", "wall", "price"] as const;

export type BaseLiquidityAccountName = (typeof LIQUIDITY_ACCOUNT_NAMES)[number];

// base + index names
export type LiquidityAccountName = OrdinalAccountName<BaseLiquidityAccountName>;

export type BaseVolumeAccountName = "mm";

export type VolumeAccountName = OrdinalAccountName<BaseVolumeAccountName>;

// type guards to discriminate liquidity and volume accounts
const isOrdinal = <T extends string>(prefix: T, name: string): name is OrdinalAccountName<T> => {
  if (name === prefix) return true;

  const tokens = name.split(prefix);
  if (tokens[0] !== "" || tokens.length !== 2) return false;
  const index = +tokens[1];
  if (!Number.isInteger(index)) {
    return false;
  }
  return true;
};

export const isLiquidityAccountName = (name: string): name is LiquidityAccountName =>
  LIQUIDITY_ACCOUNT_NAMES.some((liquidityName) => isOrdinal(liquidityName, name));

export const isVolumeAccountName = (name: string): name is VolumeAccountName =>
  isOrdinal("mm", name);

export type AccountType = "volume" | "liquidity";

export type BotAccountName<T extends AccountType = AccountType> = T extends "volume"
  ? VolumeAccountName
  : LiquidityAccountName;

export type BaseBotAccountName<T extends AccountType = AccountType> = T extends "volume"
  ? BaseVolumeAccountName
  : BaseLiquidityAccountName;

export type BaseAccountBinding<T extends AccountType> = {
  name: BotAccountName<T>;
  type: T;
  uuid: string;
  account: AccountApi | null;
};

export type VolumeAccountBinding = BaseAccountBinding<"volume">;
export type LiquidityAccountBinding = BaseAccountBinding<"liquidity">;

export type AccountBinding = VolumeAccountBinding | LiquidityAccountBinding;

export type BotAccountsMap = Partial<Record<BotAccountName, AccountApi>>;
