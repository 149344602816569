import { Mapper, entries } from "src/helpers/utils";
import { filterBoolean } from "src/helpers/utils/filterBoolean";
import { DEXNEChainExchangesMap } from "../Info/Exchanges/types";
import { DEXNEChainExchangesVersionsMap, DEXNEExchangesVersionsMap } from "./types";

export const mapChainExchangesToChainVersionExchanges: Mapper<
  DEXNEChainExchangesMap,
  DEXNEChainExchangesVersionsMap
> = (supportedChainExchanges) => {
  const chainVersionExchangesEntries = entries(supportedChainExchanges).map((entry) => {
    if (!entry) return null;

    const [chainName, exchanges] = entry;
    if (!chainName || !exchanges) return null;

    const versionExchanges = Object.values(exchanges).reduce<DEXNEExchangesVersionsMap>(
      (versionsMap, exchange) => {
        if (exchange) {
          const { dexVersion: version } = exchange;

          const currentVersionExchanges = versionsMap[version] ?? [];
          currentVersionExchanges.push(exchange);

          // eslint-disable-next-line no-param-reassign
          versionsMap[version] = currentVersionExchanges;
        }
        return versionsMap;
      },
      {}
    );

    return [chainName, versionExchanges] as const;
  });

  return Object.fromEntries(filterBoolean(chainVersionExchangesEntries));
};
