import { makeAutoObservable } from "mobx";
import {
  addWatchList,
  deleteWatchList,
  getBases,
  getExchanges,
  getWatchList,
} from "src/api/bots/DEXV2/bots";
import { getParties } from "src/api/userManager/partiesAPI";
import { getSelectorList } from "src/helpers/forms/selectors";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { Comparators } from "src/helpers/sorting";
import { IDisposable } from "src/helpers/utils";
import { DEXCommonExchangeVersion } from "src/modules/bots";
import {
  BotsListSelectorValue,
  IBotsFilter,
  IBotsSelectors,
  IRecentPartiesFilter,
  IRecentPartiesProvider,
} from "src/state/shared/types/botListsTypes";
import { DEXCommonBotsColorStatus } from "src/components/AllBots/Bots/shared/DEXCommon";
import { filterBoolean } from "src/helpers/utils/filterBoolean";
import RecentPartiesFilterStore from "../../CEX/CEXBots/RecentPartiesFilterStore";
import RecentlyAddedFilterStore from "../../CEX/CEXBots/RecentlyAddedFilterStore";
import { DEX_VERSION_OPTIONS } from "../Create/constants";
import { DEXVersionOptions } from "../Create/types";
import { IDEXNERootProvider } from "../Providers/RootProvider";
import { DEXNEBotStatusFilterStore, IDEXNEBotStatusFilter } from "./DEXNEBotFilterStore";
import { dexNEBotToListDEXNEBot, botResponseToDEXNEBot } from "./mapper";
import { IDEXNEBot, IListDEXNEBot } from "./types";
import { generateBots } from "./mocks";
import { IDEXNEChainsInfoProvider } from "../Info";

const BOTS_FETCHING_INTERVAL = 5000;

export interface IDEXNEBotsProvider {
  get allBots(): IListDEXNEBot[];
}

export class DEXNEBotsStore
  implements
    IDEXNEBotsProvider,
    IRecentPartiesProvider,
    IBotsFilter,
    IBotsSelectors<true>,
    IDisposable
{
  private _bots: IListDEXNEBot[] = [];

  private _selectedBase: string[] = [];

  private _selectedSwaps: string[] = [];

  private _selectedParties: string[] = [];

  private _selectedChains: string[] = [];

  private _selectedDEXVersions: DEXCommonExchangeVersion[] = [];

  private _allBases: string[] = [];

  private _allExchanges: string[] = [];

  private _allParties: string[] = [];

  private _intervalHandler?: ReturnType<typeof setInterval>;

  private _recentlyAddedFilter: RecentlyAddedFilterStore;

  private _recentPartiesFilter: IRecentPartiesFilter;

  private _blueStatusFilter: IDEXNEBotStatusFilter;

  private _yellowStatusFilter: IDEXNEBotStatusFilter;

  private _redStatusFilter: IDEXNEBotStatusFilter;

  private _firstLoad = true;

  private _loading = false;

  watchListEnabled = localStorage.getItem("watchListEnabled") === "true";

  private _chainsInfoProvider: IDEXNEChainsInfoProvider;

  private _party = "";

  constructor(rootProvider: IDEXNERootProvider, party?: string) {
    makeAutoObservable(this);

    this._recentlyAddedFilter = new RecentlyAddedFilterStore();
    this._recentPartiesFilter = new RecentPartiesFilterStore();

    this._blueStatusFilter = new DEXNEBotStatusFilterStore(this, DEXCommonBotsColorStatus.Blue);
    this._yellowStatusFilter = new DEXNEBotStatusFilterStore(this, DEXCommonBotsColorStatus.Yellow);
    this._redStatusFilter = new DEXNEBotStatusFilterStore(this, DEXCommonBotsColorStatus.Red);

    this._chainsInfoProvider = rootProvider.chainsInfo;

    if (party) this._setParty(party);

    makeLoggable<any>(this, {
      bots: true,
      _selectedChains: true,
      selectedChains: true,
      _allChainSelectorValues: true,
    });
  }

  chainsEnabled = true as const;

  dexVersionsEnabled = true as const;

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  get loading() {
    return this._loading;
  }

  private _setFirstLoad = (loading: boolean) => {
    this._firstLoad = loading;
  };

  private _resetLoading = () => {
    this._setLoading(false);
    this._setFirstLoad(false);
  };

  get firstLoad() {
    return this._firstLoad;
  }

  private _setBots = (bots: IDEXNEBot[], watchList: string[]) => {
    const watchListSet = new Set(watchList);

    const listBots = bots.map((bot) => {
      const listBot = dexNEBotToListDEXNEBot(bot, this._chainsInfoProvider.chainMetaMap);
      listBot.isWatched = watchListSet.has(listBot.botUUID);
      return listBot;
    });

    this._bots = listBots;
  };

  private _setAllBases = (bases: string[]) => {
    this._allBases = bases;
  };

  get allBase() {
    const selectorValues = getSelectorList(this._allBases);
    return this._sortSelectorValue(selectorValues);
  }

  private _setAllExchanges = (exchanges: string[]) => {
    this._allExchanges = exchanges;
  };

  get allExchanges() {
    const selectorValues = getSelectorList(this._allExchanges);
    return this._sortSelectorValue(selectorValues);
  }

  private _setAllParties = (parties: string[]) => {
    this._allParties = parties;
  };

  get allParties() {
    const selectorValues = getSelectorList(this._allParties);
    return this._sortSelectorValue(selectorValues);
  }

  private get _chainsInfo() {
    return filterBoolean(Object.values(this._chainsInfoProvider.chains));
  }

  private get _chainNames() {
    return this._chainsInfo.map(({ name }) => name);
  }

  get allChains() {
    return getSelectorList(this._chainNames);
  }

  get allVersions() {
    return DEX_VERSION_OPTIONS;
  }

  setSelectedBases = (options: readonly BotsListSelectorValue[]) => {
    this._selectedBase = options.map(({ value }) => value);
  };

  get selectedBase() {
    return getSelectorList(this._selectedBase);
  }

  setSelectedExchanges = (options: readonly BotsListSelectorValue[]) => {
    this._selectedSwaps = options.map(({ value }) => value);
  };

  get selectedExchanges() {
    return getSelectorList(this._selectedSwaps);
  }

  setSelectedParties = (options: readonly BotsListSelectorValue[]) => {
    this._selectedParties = options.map(({ value }) => value);
  };

  get selectedParties() {
    return getSelectorList(this._selectedParties);
  }

  setSelectedChains = (options: readonly BotsListSelectorValue[]) => {
    this._selectedChains = options.map(({ value }) => value);
  };

  get selectedChains() {
    return this.allChains.filter(({ value }) => this._selectedChains.includes(value));
  }

  setSelectedVersions = (options: readonly BotsListSelectorValue[]) => {
    const versionsOptions = options as DEXVersionOptions[];
    this._selectedDEXVersions = versionsOptions.map(({ value }) => value);
  };

  get selectedVersions() {
    return this.allVersions.filter(({ value }) => this._selectedDEXVersions.includes(value));
  }

  private _sortSelectorValue = (values: BotsListSelectorValue[]) =>
    values.slice().sort((that, other) => Comparators.String(that.value, other.value));

  get allBots() {
    return this._bots;
  }

  get bots() {
    return this._bots.filter(
      (el) =>
        this._baseFilter(el) &&
        this._swapsFilter(el) &&
        this._partiesFilter(el) &&
        this._chainsFilter(el) &&
        this._versionsFilter(el) &&
        this._watchListFilter(el) &&
        this._statusFilter(el) &&
        this._recentPartiesFilter.filter(el)
    );
  }

  get totalBotsCount(): number {
    return this._bots.length;
  }

  get redCount(): number {
    return this._redStatusFilter.count;
  }

  get redStatus(): boolean {
    return this._redStatusFilter.isEnabled;
  }

  get blueCount(): number {
    return this._blueStatusFilter.count;
  }

  get blueStatus(): boolean {
    return this._blueStatusFilter.isEnabled;
  }

  get yellowCount(): number {
    return this._yellowStatusFilter.count;
  }

  get yellowStatus(): boolean {
    return this._yellowStatusFilter.isEnabled;
  }

  get recentlyAddedEnabled() {
    return this._recentlyAddedFilter.isEnabled;
  }

  get recentlyAddedCount() {
    return 0;
    // return this._bots.filter(this._recentlyAddedFilter.filter).length;
  }

  get recentParties() {
    return this._recentPartiesFilter.recentParties;
  }

  get isPartyMode() {
    return Boolean(this._party);
  }

  private _setParty = (party: string) => {
    this._party = party;
  };

  togglePartySelection = (name: string) => this._recentPartiesFilter.togglePartySelection(name);

  toggleWatchList = () => {
    this.watchListEnabled = !this.watchListEnabled;
    localStorage.setItem("watchListEnabled", String(this.watchListEnabled));
  };

  removeBaseSelected = (item: string) => {
    this._selectedBase = this._selectedBase.filter((value) => value !== item);
  };

  removeExchSelected = (item: string) => {
    this._selectedSwaps = this._selectedSwaps.filter((value) => value !== item);
  };

  removePartiesSelected = (item: string) => {
    this._selectedParties = this._selectedParties.filter((value) => value !== item);
  };

  removeSelectedChain = (item: string) => {
    this._selectedChains = this._selectedChains.filter((value) => value !== item);
  };

  removeSelectedVersion = (item: string) => {
    const version = item as DEXCommonExchangeVersion;
    this._selectedDEXVersions = this._selectedDEXVersions.filter((value) => value !== version);
  };

  private _watchListFilter = ({ isWatched }: IListDEXNEBot) => !this.watchListEnabled || isWatched;

  private _toggleIsWatched = (bot: IListDEXNEBot) => {
    // eslint-disable-next-line no-param-reassign
    bot.isWatched = !bot.isWatched;
  };

  toggleIsWatched = async (bot_uuid: string) => {
    this._setLoading(true);

    const bot = this._getBotById(bot_uuid);
    if (!bot) return;

    const currentWatched = bot.isWatched;

    const updateWatchList = currentWatched ? deleteWatchList : addWatchList;

    try {
      this._toggleIsWatched(bot);

      const { isError } = await updateWatchList([bot.botUUID]);
      if (isError) {
        this._toggleIsWatched(bot);
      }
    } catch (err) {
      this._toggleIsWatched(bot);
      logError(err);
    } finally {
      this._setLoading(false);
    }
  };

  private _getBotById = (botUUID: string) => this._bots.find((item) => item.botUUID === botUUID);

  private _getBases = async () => {
    const { isError, data } = await getBases(this._party);

    if (!isError) {
      this._setAllBases(data);
    }
  };

  private _getExchanges = async () => {
    const { isError, data } = await getExchanges(this._party);

    if (!isError) {
      this._setAllExchanges(data);
    }
  };

  private _getParties = async () => {
    const { data: allParties } = await getParties();

    this._setAllParties(allParties);
  };

  private _getWatchList = async () => {
    try {
      const { isError, data } = await getWatchList();

      if (!isError) {
        return data.bots;
      }
      return [];
    } catch {
      return [];
    }
  };

  private _getBotsWatched = async () => {
    try {
      const watchList = await this._getWatchList();

      // const { isError, data } = await getBotStatuses(this._party);
      const isError = false;
      const data = generateBots(10);

      if (!isError) {
        const bots = data.map(botResponseToDEXNEBot);
        this._setBots(bots, watchList);
      }
    } catch (err) {
      logError(err);
    }
  };

  private _fetchAllBots = async () => {
    this._setLoading(true);
    try {
      await Promise.all([this._getBotsWatched(), this._getBases(), this._getExchanges()]);

      if (!this.isPartyMode) await this._getParties();

      this._resetLoading();
    } catch (err) {
      logError(err);
      this._resetLoading();
    }
  };

  resumeBotsFetching = () => {
    this._fetchAllBots();
    this._intervalHandler = setInterval(() => this._fetchAllBots(), BOTS_FETCHING_INTERVAL);
  };

  suspendBotsFetching = () => {
    clearInterval(this._intervalHandler);
    this._intervalHandler = undefined;
  };

  private _baseFilter = ({ base }: IListDEXNEBot) =>
    this._selectedBase.length === 0 || this._selectedBase.includes(base);

  private _swapsFilter = ({ exchange }: IListDEXNEBot) =>
    this._selectedSwaps.length === 0 || this._selectedSwaps.includes(exchange);

  private _partiesFilter = ({ party }: IListDEXNEBot) =>
    this._selectedParties.length === 0 || this._selectedParties.includes(party);

  private _chainsFilter = ({ chainName }: IListDEXNEBot) =>
    this._selectedChains.length === 0 || this._selectedChains.includes(chainName);

  private _versionsFilter = ({ dexVersion }: IListDEXNEBot) =>
    this._selectedDEXVersions.length === 0 || this._selectedDEXVersions.includes(dexVersion);

  private _statusFilter = (bot: IListDEXNEBot) => {
    const allFiltersDisabled =
      !this._redStatusFilter.isEnabled &&
      !this._yellowStatusFilter.isEnabled &&
      !this._blueStatusFilter.isEnabled;

    return (
      allFiltersDisabled ||
      this._redStatusFilter.filter(bot) ||
      this._yellowStatusFilter.filter(bot) ||
      this._blueStatusFilter.filter(bot)
    );
  };

  toggleRedStatus = () => {
    this._redStatusFilter.toggleEnabled();
  };

  toggleBlueStatus = () => {
    this._blueStatusFilter.toggleEnabled();
  };

  toggleYellowStatus = () => {
    this._yellowStatusFilter.toggleEnabled();
  };

  toggleRecentlyAddedEnabled = () => {
    this._recentlyAddedFilter.toggleEnabled();
  };

  destroy = () => {};
}
