import { generateTokenTicker } from "src/state/CEX/shared/mocks";
import { faker } from "@faker-js/faker";
import { DEXCommonBotStatus, DEXCommonExchangeVersion } from "src/modules/bots";
import { IDEXNEBotDTO, DEXNELimitInfoDTO, DEXNEVolumeInfoDTO } from "./types";

const generateBotStatus = (): DEXCommonBotStatus => faker.number.int({ min: 0, max: 5 });

const generateVolumeInfo = (): DEXNEVolumeInfoDTO => ({
  volume_bot_last_trade_status: faker.string.alphanumeric({ length: { min: 5, max: 100 } }),
  volume_bot_last_trade_date_unix: +faker.date.past(),
  volume_bot_next_trade_date_unix: +faker.date.future(),
  volume_descr: faker.number.int({ min: 0, max: 3 }),
});

const generateLimitInfo = (): DEXNELimitInfoDTO => ({
  limit_bot_last_trade_status: faker.string.alphanumeric({ length: { min: 5, max: 100 } }),
  limit_bot_last_trade_date_unix: +faker.date.past(),
  limit_bot_next_trade_date_unix: +faker.date.future(),
  limit_descr: faker.number.int({ min: 0, max: 3 }),
});

export const generateBot = (botUUID: string): IDEXNEBotDTO => ({
  bot_uuid: botUUID,
  base: generateTokenTicker(),
  quote: generateTokenTicker(),
  base_addr: faker.finance.ethereumAddress(),
  quote_addr: faker.finance.ethereumAddress(),
  pair_addr: faker.finance.ethereumAddress(),
  chain_name: "polygon",
  exchange: "quickswap",
  is_stopped: faker.datatype.boolean(),
  name: faker.string.alphanumeric({ length: { min: 5, max: 25 } }),
  link: faker.string.alphanumeric({ length: { min: 5, max: 50 } }),
  party: faker.string.alphanumeric({ length: { min: 5, max: 15 } }),
  status: generateBotStatus(),
  dex_version: faker.helpers.arrayElement([
    DEXCommonExchangeVersion.V2,
    DEXCommonExchangeVersion.V3,
  ]),
  ...generateVolumeInfo(),
  ...generateLimitInfo(),
});

export const generateBots = (count: number): IDEXNEBotDTO[] =>
  Array.from({ length: count }, () => generateBot(faker.string.uuid()));
