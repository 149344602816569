import { Fraction, Percent } from "@uniswap/sdk-core";
import { AbstractNativeCurrency } from "./AbstractNativeCurrency";

export const ADDRESS_ZERO = `0x${"0".repeat(40)}`;

export const ZERO_BYTES_STRING = "0x";

// one basis JSBI.BigInt
export const BIPS_BASE = 10000;

export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(100, BIPS_BASE); // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(300, BIPS_BASE); // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(500, BIPS_BASE); // 5%

export const ZERO_PERCENT = new Percent("0");
export const ONE_HUNDRED_PERCENT = new Percent("1");

export const ABSTRACT_NATIVE_CURRENCY = new AbstractNativeCurrency();
export const ZERO_FRACTION = new Fraction("0");

export const MAX_HIGH_SLIPPAGE = new Percent(1, 2);
export const MIN_HIGH_SLIPPAGE = new Percent(1, 100);
