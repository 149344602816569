import { IReactionDisposer, makeAutoObservable, observable, reaction } from "mobx";
import { computedFn } from "mobx-utils";
import { Row } from "react-table";
import { GetPartyExchangesResponse, getPartyExchanges } from "src/api/bots/CEX/accounting";
import { DateTimeRange } from "src/components/shared/DatePickers/shared/models/dateTimeRange";
import { rangeToText } from "src/components/shared/DatePickers/shared/utils/time-utils";
import { SelectorProps } from "src/components/shared/Forms/Selectors";
import { filterDuplicates } from "src/helpers/array";
import { toCSV } from "src/helpers/csv";
import {
  DEFAULT_FULL_DATE_FORMAT,
  getCurrentUnix,
  getDateTimeRangeFromUtc,
  unixToUTCFormat,
} from "src/helpers/dateUtils";
import { saveCSV } from "src/helpers/download";
import { RowSerialiazer } from "src/helpers/google/sheets";
import { makeLoggable } from "src/helpers/logger";
import { roundToTwoDecimals } from "src/helpers/rounding";
import { IDisposable, Mapper, entries } from "src/helpers/utils";
import { StringSelectorValue } from "src/state/UserManager/Scopes/EditScopeStore";
import { RangePickerStore, IUseRangePicker } from "src/state/shared/RangePicker";
import { UrlSearchParamsStore } from "src/state/shared/UrlSearchParams";
import { IQueryHistory } from "src/state/shared/UrlSearchParams/types";
import StringSelectorDataProvider from "./SelectorProvider";
import StringSelectorStore, { IStringSelectorStore } from "./StringSelectorStore";
import { UrlParams, searchParamsValidationSchema } from "./validationSchemes";

export type ExchangeAccounting = {
  party: string;
  team: string;
  trader: string;
  currentExchanges: number;
  currentExchangesNames: string[];
  averageExchanges: number;
  averageExchangesNames: string[];
  totalUnique: number;
  index: number;
};

export type ExportDataType = "full" | "filtered";

const partyExchangesRespToAccounting: Mapper<GetPartyExchangesResponse, ExchangeAccounting[]> = (
  response
) =>
  entries(response.current).map(([party, partyExchanges], index) => {
    const {
      exchanges,
      avg_exchange_number,
      admin_user,
      admin_user_group,
      current_exchange_number,
      total_uniq_exchange_number,
    } = partyExchanges;

    const exchangesEntries = entries(exchanges);

    return {
      index: index + 1,
      party,
      team: admin_user_group,
      trader: admin_user,
      currentExchanges: current_exchange_number,
      totalUnique: total_uniq_exchange_number,
      averageExchanges: roundToTwoDecimals(avg_exchange_number),
      currentExchangesNames: exchangesEntries
        .filter(([, { current_active }]) => current_active)
        .map(([name]) => name),
      averageExchangesNames: exchangesEntries
        .filter(([, { avg_active }]) => avg_active)
        .map(([name]) => name),
    };
  });

interface CEXDashboardBotParams {
  uuid: string;
  party: string;
}

type AccountingSelectors = "exchange" | "trader" | "team" | "party";

export default class ExchangeAccountingStore implements IDisposable, IUseRangePicker {
  private _loading = false;

  private _botParams: CEXDashboardBotParams = {
    party: "",
    uuid: "",
  };

  private _data: ExchangeAccounting[] = [];

  private _selectedExchangesState: IStringSelectorStore;

  private _selectedTradersState: IStringSelectorStore;

  private _selectedPartiesState: IStringSelectorStore;

  private _selectedTeamsState: IStringSelectorStore;

  private _rangePickerState: RangePickerStore;

  private _urlSearchParamsState: UrlSearchParamsStore<UrlParams>;

  private _querySearchParamsReaction?: IReactionDisposer;

  private _tableRows: Row<ExchangeAccounting>[] = [];

  constructor(searchParamsProps: IQueryHistory) {
    makeAutoObservable<this, "_tableRows">(this, {
      selectorValue: false,
      selectorOptions: false,
      selectorProps: false,
      _tableRows: observable.shallow,
    });

    this._rangePickerState = new RangePickerStore(this, { month: 1 });

    this._selectedExchangesState = new StringSelectorStore(
      new StringSelectorDataProvider(() => this._exchanges)
    );

    this._selectedTradersState = new StringSelectorStore(
      new StringSelectorDataProvider(() => this._traders)
    );

    this._selectedPartiesState = new StringSelectorStore(
      new StringSelectorDataProvider(() => this._parties)
    );

    this._selectedTeamsState = new StringSelectorStore(
      new StringSelectorDataProvider(() => this._teams)
    );

    this._urlSearchParamsState = new UrlSearchParamsStore(this, {
      ...searchParamsProps,
      validationSchema: searchParamsValidationSchema,
      localStorageName: "exchangeAccounting",
    });

    makeLoggable(this, { selectedData: true });
  }

  get loading() {
    return this._loading;
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  setTableRows = (rows: Row<object>[]) => {
    this._tableRows = rows as Row<ExchangeAccounting>[];
  };

  private get _tableRowsData() {
    return this._tableRows.map(({ original }) => original);
  }

  setBotParams = (botParams: CEXDashboardBotParams) => {
    this._botParams = botParams;
  };

  get botParams() {
    return this._botParams;
  }

  get data() {
    return this._data;
  }

  private _setData = (data: ExchangeAccounting[]) => {
    this._data = data;
  };

  get selectedData() {
    const filters = [
      this._exchangesFilter,
      this._tradersFilter,
      this._teamsFilter,
      this._partiesFilter,
    ];

    return this.data.filter((accounting) => filters.every((filter) => filter(accounting)));
  }

  get selectedCount() {
    return this._tableRows.length;
  }

  private get _selectedExchanges() {
    return this._selectedExchangesState.selectedData;
  }

  private _exchangesFilter = (accounting: ExchangeAccounting) => {
    if (!this._selectedExchanges.length) return true;
    return accounting.currentExchangesNames.some((exchange) =>
      this._selectedExchanges.includes(exchange)
    );
  };

  private get _selectedTraders() {
    return this._selectedTradersState.selectedData;
  }

  private _tradersFilter = (accounting: ExchangeAccounting) => {
    if (!this._selectedTraders.length) return true;
    return this._selectedTraders.includes(accounting.trader);
  };

  private get _selectedParties() {
    return this._selectedPartiesState.selectedData;
  }

  private _partiesFilter = (accounting: ExchangeAccounting) => {
    if (!this._selectedParties.length) return true;
    return this._selectedParties.includes(accounting.party);
  };

  private get _selectedTeams() {
    return this._selectedTeamsState.selectedData;
  }

  private _teamsFilter = (accounting: ExchangeAccounting) => {
    if (!this._selectedTeams.length) return true;
    return this._selectedTeams.includes(accounting.team);
  };

  private get _exchanges() {
    const exchanges = this.data.flatMap(({ currentExchangesNames }) => currentExchangesNames);
    return filterDuplicates(exchanges);
  }

  private get _traders() {
    const traders = this.data.map(({ trader }) => trader);
    return filterDuplicates(traders);
  }

  private get _teams() {
    const teams = this.data.map(({ team }) => team);
    return filterDuplicates(teams);
  }

  private get _parties() {
    const parties = this.data.map(({ party }) => party);
    return filterDuplicates(parties);
  }

  selectorValue = computedFn((key: AccountingSelectors): StringSelectorValue[] => {
    switch (key) {
      case "exchange": {
        return this._selectedExchangesState.value;
      }
      case "trader": {
        return this._selectedTradersState.value;
      }
      case "team": {
        return this._selectedTeamsState.value;
      }
      case "party": {
        return this._selectedPartiesState.value;
      }
    }
  });

  selectorOnChange = (
    key: AccountingSelectors
  ): ((data: readonly StringSelectorValue[]) => void) => {
    switch (key) {
      case "exchange": {
        return this._selectedExchangesState.onChange;
      }
      case "trader": {
        return this._selectedTradersState.onChange;
      }
      case "team": {
        return this._selectedTeamsState.onChange;
      }
      case "party": {
        return this._selectedPartiesState.onChange;
      }
    }
  };

  selectorOptions = computedFn((key: AccountingSelectors): StringSelectorValue[] => {
    switch (key) {
      case "exchange": {
        return this._selectedExchangesState.options;
      }
      case "trader": {
        return this._selectedTradersState.options;
      }
      case "team": {
        return this._selectedTeamsState.options;
      }
      case "party": {
        return this._selectedPartiesState.options;
      }
    }
  });

  selectorProps = (
    key: AccountingSelectors
  ): Pick<SelectorProps<StringSelectorValue, true, any>, "options" | "value" | "onChange"> => ({
    options: this.selectorOptions(key),
    value: this.selectorValue(key),
    onChange: this.selectorOnChange(key),
  });

  resetSelectors = () => {
    this._selectedExchangesState.reset();
    this._selectedTeamsState.reset();
    this._selectedTradersState.reset();
    this._selectedPartiesState.reset();
  };

  get selectedRange() {
    return this._rangePickerState.range;
  }

  private get _querySearchParams(): UrlParams {
    return { from: this._rangePickerState.start, to: this._rangePickerState.end };
  }

  setRange = (range: DateTimeRange) => {
    this._rangePickerState.setRange(range);
  };

  setInitialQueries = (queryObj: Partial<UrlParams>) => {
    const { from, to } = queryObj;

    if (from && to) this._rangePickerState.setStateRange(getDateTimeRangeFromUtc(from, to));
  };

  private _getDataCSVRows = (data: ExchangeAccounting[]) =>
    data.map((accounting) => Object.values(accounting).map(String));

  accountingToSheets: RowSerialiazer<ExchangeAccounting> = (accounting) =>
    Object.values(accounting).map((value) => {
      switch (typeof value) {
        case "object": {
          return value.toString();
        }
        case "symbol":
        case "undefined":
        case "function": {
          return "";
        }
        default: {
          return value;
        }
      }
    });

  private _getDataCSVHeaders(data: ExchangeAccounting[]) {
    return Object.keys(data[0] ?? []);
  }

  getExportData = (type: ExportDataType) => {
    if (type === "full") return this.data;
    const filteredTableData = this._tableRowsData;
    const filteredData = this.selectedData;
    return filteredTableData.length ? filteredTableData : filteredData;
  };

  private _getTimeTextFormat = (delimiter?: string) => {
    if (!delimiter) return DEFAULT_FULL_DATE_FORMAT;

    const dateFormat = `YYYY${delimiter}MM${delimiter}DD`;
    const timeFormat = `HH${delimiter}mm${delimiter}ss`;

    return `${dateFormat} ${timeFormat}`;
  };

  getExportDocumentTitle = (type: ExportDataType, timeDelimiter?: string) => {
    const range = this.selectedRange;
    const timeFormat = this._getTimeTextFormat(timeDelimiter);
    const rangeText = rangeToText(range, timeFormat);
    const currentUnix = getCurrentUnix();
    const currentDate = unixToUTCFormat(currentUnix, timeFormat);
    const typeText = type === "filtered" ? "Filtered" : "Original";

    return `Exchange Accounting ${typeText} ${rangeText} (${currentDate})`;
  };

  private _downloadCSV = (type: ExportDataType) => {
    const data = this.getExportData(type);
    const dataRows = this._getDataCSVRows(data);
    const dataHeaders = this._getDataCSVHeaders(data);
    if (dataRows.length && dataHeaders.length) {
      const csvData = toCSV(dataRows, dataHeaders);
      const filename = this.getExportDocumentTitle(type, "-");
      saveCSV(csvData, filename);
    }
  };

  downloadFullCSV = () => {
    this._downloadCSV("full");
  };

  downloadFilteredCSV = () => {
    this._downloadCSV("filtered");
  };

  private get _rangeParams() {
    const [start, end] = this.selectedRange;
    if (!start || !end) return undefined;
    return {
      start: start.unix().toString(),
      stop: end.unix().toString(),
    };
  }

  private _getExchangesAccounting = async (resetSelectors?: boolean) => {
    if (resetSelectors) {
      this.resetSelectors();
    }
    this._setLoading(true);
    try {
      const { isError, data } = await getPartyExchanges(this._rangeParams);
      if (!isError) {
        const accounting = partyExchangesRespToAccounting(data);
        this._setData(accounting);
      }
    } catch {
      this._setData([]);
    } finally {
      this._setLoading(false);
    }
  };

  getExchangesAccounting = async () => {
    await this._getExchangesAccounting();
  };

  refreshExchangesAccounting = async () => {
    await this._getExchangesAccounting(true);
  };

  loadData = () => {
    this.getExchangesAccounting();
  };

  destroy = () => {};

  subscribe = () => {
    this._querySearchParamsReaction = reaction(
      () => this._querySearchParams,
      (query) => this._urlSearchParamsState.setAllQueryUrl(query),
      { fireImmediately: true }
    );
  };

  unsubscribe = () => this._querySearchParamsReaction?.();
}
