import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { IDisposable } from "src/helpers/utils";
import { ChainsStore, IChainsProvider } from "./Chains";
import { ChainsMetaStore, IChainsMetaProvider } from "./ChainsMeta";
import { ChainExchangesStore, IChainExchangesProvider } from "./Exchanges";

export interface IDEXNEChainsInfoProvider
  extends Pick<IChainsProvider, "chains">,
    Pick<IChainsMetaProvider, "chainMetaMap">,
    Pick<IChainExchangesProvider, "chainExchanges"> {
  getChainsInfo: () => Promise<void>;
}

export class DEXNEChainsInfoStore implements IDEXNEChainsInfoProvider, IDisposable {
  private _chainsState: IChainsProvider;

  private _chainExchangesState: IChainExchangesProvider;

  private _chainConfigsState: IChainsMetaProvider;

  private _disposers: IDisposable[];

  constructor() {
    makeAutoObservable(this);

    const chainsState = new ChainsStore();

    const chainExchangesState = new ChainExchangesStore();

    const chainsMetaState = new ChainsMetaStore();

    this._disposers = [chainsState, chainExchangesState, chainsMetaState];

    this._chainsState = chainsState;
    this._chainExchangesState = chainExchangesState;
    this._chainConfigsState = chainsMetaState;

    makeLoggable(this, { chainExchanges: true, chains: true, chainMetaMap: true });
  }

  get chains() {
    return this._chainsState.chains;
  }

  get chainExchanges() {
    return this._chainExchangesState.chainExchanges;
  }

  get chainMetaMap() {
    return this._chainConfigsState.chainMetaMap;
  }

  private _getChains = async () => {
    await this._chainsState.getChains();
  };

  private _getExchanges = async () => {
    await this._chainExchangesState.getChainExchanges();
  };

  private _getChainsMeta = async () => {
    await this._chainConfigsState.getChainMeta();
  };

  getChainsInfo = async () => {
    try {
      await Promise.all([this._getChains(), this._getExchanges(), this._getChainsMeta()]);
    } catch (err) {
      logError(err);
    }
  };

  destroy = () => {
    this._disposers.forEach((disposer) => disposer.destroy());
  };
}
