import { observer } from "mobx-react-lite";
import { InputStyle } from "../../Input";
import * as styles from "./style";

export interface PercentRangeInputOwnProps
  extends Pick<
      React.ComponentPropsWithoutRef<"input">,
      "value" | "min" | "max" | "step" | "onChange" | "id"
    >,
    InputStyle.StyledInputProps {}
export interface PercentRangeInputProps
  extends PercentRangeInputOwnProps,
    Omit<React.ComponentPropsWithoutRef<"div">, keyof PercentRangeInputOwnProps> {}

export const PercentRangeInput = observer(
  ({
    id,
    value: currentValue = 0,
    min = "0",
    max = "100",
    step = "1",
    onChange,
    error = false,
    ...props
  }: PercentRangeInputProps) => {
    const currentNumberValue = currentValue || 0;
    const currentPercent = (100 * +currentValue) / +max;

    return (
      <styles.PercentRangeInputWrapper {...props}>
        <styles.StyledRangeInput
          min={min}
          max={max}
          step={step}
          value={currentNumberValue}
          onChange={onChange}
          style={
            {
              // using variable instead of props to avoid styles recreation
              [styles.PERCENT_VAR]: `${currentPercent}%`,
            } as React.CSSProperties
          }
        />

        <styles.StyledPercentInput
          id={id}
          type="number"
          min={min}
          max={max}
          step={step}
          value={currentValue}
          onChange={onChange}
          error={error}
        />
      </styles.PercentRangeInputWrapper>
    );
  }
);
