import { makeAutoObservable, runInAction } from "mobx";
import { getPartyAccounts } from "src/api/userManager/partiesAPI";
import { getSelectorList } from "src/helpers/forms/selectors";
import { logError } from "src/helpers/network/logger";
import { Account } from "src/modules/userManager";

interface IAccountsConsumer {
  party: string;
}

class AccountReceiver {
  private _accounts: Record<string, Account[]> = {};

  private _mainState: IAccountsConsumer;

  constructor(state: IAccountsConsumer) {
    makeAutoObservable(this);

    this._mainState = state;
  }

  get exchanges() {
    return Object.keys(this._accounts);
  }

  get exchOptions() {
    return getSelectorList(this.exchanges);
  }

  get accountsMap() {
    const hashMap: Record<string, Account> = {};

    for (const key of Object.keys(this._accounts)) {
      if (Array.isArray(this._accounts[key])) {
        for (const el of this._accounts[key]) {
          hashMap[el.uuid] = el;
        }
      }
    }

    return hashMap;
  }

  get accounts() {
    return this._accounts;
  }

  private get _party() {
    return this._mainState.party;
  }

  loadAccounts = async () => {
    this._getAccounts(this._party);
  };

  private _getAccounts = async (party: string) => {
    try {
      const { data, isError } = await getPartyAccounts(party, false);

      if (!isError) {
        runInAction(() => {
          this._accounts = data;
        });
      }
    } catch (err) {
      logError(err);
    }
  };

  destroy = () => {};
}

export default AccountReceiver;
