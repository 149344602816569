import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { IconWrapper } from "src/components/AllBots/Bots/shared/shared";
import { CheckBox } from "src/components/shared/CheckBox";
import { MenuItemData } from "src/components/shared/SwitchMenu/MenuItem";
import { useFindWidth } from "src/hooks/useFindWidth";
import Icons from "src/icons/Icons";
import { BotsType } from "src/modules/bots";
import {
  IBotsBlueFilter,
  IBotsRecentlyAddedFilter,
  IBotsRedFilter,
  IBotsWatchList,
  IBotsYellowFilter,
} from "src/state/shared/types/botListsTypes";
import * as styles from "./style";

export interface BotFilterPanelProps
  extends IBotsRedFilter,
    IBotsBlueFilter,
    IBotsYellowFilter,
    IBotsWatchList,
    IBotsRecentlyAddedFilter {
  currentBots: BotsType;
  toggleBot: (value: BotsType) => void;
  totalCount: number;
}

export interface StringMenuItem<T extends string> extends MenuItemData {
  label: T;
  value: T;
}

type BotsTypeMenuOptions<T extends BotsType = BotsType> = T extends BotsType
  ? StringMenuItem<T>
  : never;

const BOTS_TYPES_MENU_ITEMS: BotsTypeMenuOptions[] = [
  { label: "CEX", value: "CEX" },
  { label: "DEX", value: "DEX" },
  { label: "DEXV2", value: "DEXV2" },
  // { label: "DEXNE", value: "DEXNE" },
];

export const BotFilterPanel = observer(
  ({
    currentBots,
    toggleBot,
    toggleRedStatus,
    toggleYellowStatus,
    toggleBlueStatus,
    toggleRecentlyAddedEnabled,
    redStatus,
    yellowStatus,
    blueStatus,
    redCount,
    yellowCount,
    blueCount,
    totalCount,
    recentlyAddedCount,
    recentlyAddedEnabled,
    watchListEnabled,
    toggleWatchList,
  }: BotFilterPanelProps) => {
    const showMobVers = useFindWidth(950);

    const onChangeKey = useCallback(
      (value: string) => {
        const key = value as BotsType;
        toggleBot(key);
      },
      [toggleBot]
    );

    return (
      <styles.Container>
        <styles.ToggleFavoriteWrapper>
          <styles.Toggle>
            <styles.BotsSwitching
              border={false}
              selectedItem={currentBots}
              direction="row"
              menuItems={BOTS_TYPES_MENU_ITEMS}
              onChange={onChangeKey}
            />
          </styles.Toggle>
          {showMobVers ? (
            <IconWrapper onClick={toggleWatchList}>
              {watchListEnabled ? Icons.starTrue() : Icons.starFalse()}
            </IconWrapper>
          ) : null}
        </styles.ToggleFavoriteWrapper>

        <styles.FilterPanel>
          <styles.CellFilter>
            {Icons.redStatus()}
            <CheckBox onChange={toggleRedStatus} checked={redStatus} />

            <styles.Count>{redCount}</styles.Count>
          </styles.CellFilter>

          <styles.CellFilter>
            {Icons.alert()}
            <CheckBox onChange={toggleYellowStatus} checked={yellowStatus} />

            <styles.Count>{yellowCount}</styles.Count>
          </styles.CellFilter>

          <styles.CellFilter>
            {Icons.close()}
            <CheckBox onChange={toggleBlueStatus} checked={blueStatus} />

            <styles.Count>{blueCount}</styles.Count>
          </styles.CellFilter>

          <styles.CellFilter>
            {Icons.totalBots()}
            <styles.Count>{totalCount}</styles.Count>
          </styles.CellFilter>

          <styles.CellFilter>
            {Icons.recentlyAdded()}
            <CheckBox onChange={toggleRecentlyAddedEnabled} checked={recentlyAddedEnabled} />
            <styles.Count>{recentlyAddedCount}</styles.Count>
          </styles.CellFilter>
        </styles.FilterPanel>
      </styles.Container>
    );
  }
);
