import { IDEXNEBotDTO } from "src/state/DEX_NE/bots";
import { generateBot } from "src/state/DEX_NE/bots/mocks";

export const generateMockBot = (botId: string): IDEXNEBotDTO => {
  const bot = generateBot(botId);
  return {
    ...bot,
    base: "$WIF",
    base_addr: "EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm",
    quote: "SOL",
    quote_addr: "So11111111111111111111111111111111111111112",
    pair_addr: "4E6q7eJE6vBNdquqzYYi5gvzd5MNpwiQKhjbRTRQGuQd",
    exchange: "Orca",
    link: "https://dexscreener.com/solana/4e6q7eje6vbndquqzyyi5gvzd5mnpwiqkhjbrtrqguqd",
    chain_name: "Solana",
  };
};
