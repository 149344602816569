import { makeAutoObservable } from "mobx";
import { IDisposable } from "src/helpers/utils";
import { DEXNELimitStrategy } from "../types/settings";

export type LimitStrategiesOnChange = (strategies: DEXNELimitStrategy[]) => void;

export interface ILimitStrategiesBinder {
  bindStrategies: (
    strategies: DEXNELimitStrategy[],
    onChange: LimitStrategiesOnChange | null
  ) => () => void;

  setStrategies: (strategies: DEXNELimitStrategy[]) => void;

  get strategies(): DEXNELimitStrategy[];
}

export class LimitStrategiesBinderStore implements ILimitStrategiesBinder, IDisposable {
  private _limitStrategies: DEXNELimitStrategy[] = [];

  private _onLimitStrategiesChange: LimitStrategiesOnChange | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  private _unbindLimitStrategies = () => {
    this._limitStrategies = [];
    this._onLimitStrategiesChange = null;
  };

  bindStrategies = (strategies: DEXNELimitStrategy[], onChange: LimitStrategiesOnChange | null) => {
    this._limitStrategies = strategies;
    this._onLimitStrategiesChange = onChange;
    return this._unbindLimitStrategies;
  };

  setStrategies = (strategies: DEXNELimitStrategy[]) => {
    this._onLimitStrategiesChange?.(strategies);
  };

  get strategies() {
    return this._limitStrategies;
  }

  destroy = () => {
    this._unbindLimitStrategies();
  };
}
