import { DeepOmit, delay } from "src/helpers/utils";
import { ApiResponse } from "src/modules/network";
import { DEXNECreateSettings } from "src/state/DEX_NE/shared/types";

export type RegisterBotRequest = DeepOmit<DEXNECreateSettings, "version">;

export const createBot = async (
  party: string,
  data: RegisterBotRequest
): Promise<ApiResponse<{}>> => {
  await delay(1000);
  return {
    data: {},
    isError: false,
  };
};
