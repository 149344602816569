import { makeAutoObservable } from "mobx";
import { Account } from "src/modules/userManager";
import AccountReceiver from "./AccountReceiver";

interface ExchangeManager {
  exchange: string;
}

interface InitialStates {
  mainState: AccountReceiver;
  exchState: ExchangeManager;
}

const getAccountOption = (acc: Account) => ({ value: acc.uuid, label: acc.name });

const getAccountOptionWithExch = (acc: Account) => ({
  value: acc.uuid,
  label: `${acc.name} (${acc.exchange})`,
});

class AccountListStore {
  private _mainState: AccountReceiver;

  private _exchangeState: ExchangeManager;

  private _currentAccUUID = "";

  constructor({ mainState, exchState }: InitialStates) {
    makeAutoObservable(this);

    this._mainState = mainState;
    this._exchangeState = exchState;
  }

  private get _exchange() {
    return this._exchangeState.exchange;
  }

  get accountList() {
    if (this._exchange) return this._mainState.accounts[this._exchange];

    return [];
  }

  get accountOptions() {
    return this.accountList.map((acc) => getAccountOption(acc));
  }

  get selectAccount() {
    const acc = this.accountList.find((el) => el.uuid === this._currentAccUUID);

    if (acc) return acc;

    return null;
  }

  get selectAccountOption() {
    if (this.selectAccount) return getAccountOption(this.selectAccount);

    return null;
  }

  get selectAccountOptionWithExch() {
    if (this.selectAccount) return getAccountOptionWithExch(this.selectAccount);

    return null;
  }

  get fullAccOptions() {
    return this._fullAccList.map((acc) => getAccountOptionWithExch(acc));
  }

  private get _fullAccList() {
    return Object.values(this._mainState.accounts).flatMap((acc) => acc);
  }

  getAccountNames = (uuids: string[]) => {
    const accNames: string[] = [];

    uuids.forEach((uuid) => {
      const acc = this._fullAccList.find((el) => el.uuid === uuid);

      if (acc) accNames.push(`${acc.name} (${acc.exchange})`);
    });

    return accNames;
  };

  setCurrentAccount = (uuid: string) => {
    this._currentAccUUID = uuid;
  };
}

export default AccountListStore;
