import { toPx } from "src/helpers/styled/units";
import styled from "styled-components";
import { ScrollSize } from "../../shared/shared";

interface SideMenuButtonProps {
  isMenuVisible: boolean;
}

export const Container = styled.div`
  display: contents;
`;

interface SideMenuWrapperProps {
  isVisible: boolean;
  headerHeight: number;
}

export const SideMenuWrapper = styled.div<SideMenuWrapperProps>`
  position: fixed;
  top: ${({ headerHeight }) => toPx(headerHeight)};
  left: ${({ isVisible }) => (isVisible ? "0" : "-80px")};
  bottom: 0;

  display: flex;
  justify-content: center;

  border-right: 1px solid rgba(145, 158, 171, 0.2);
  background-color: ${({ theme }) => theme.contentBackgroundColor};

  overflow-y: auto;
  ${ScrollSize}

  transition: left 0.3s ease-in-out;

  z-index: 1000;
`;

export const SideMenuContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const SideMenuButton = styled.button<SideMenuButtonProps>`
  position: relative;

  width: 20px;
  height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0px 15px;

  cursor: pointer;
  border: 0;
  background-color: transparent;
  transform: rotate(${({ isMenuVisible }) => (isMenuVisible ? "45deg" : "0deg")});
  transition: transform 0.3s ease-in-out;

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 20px;
    background-color: ${({ theme }) => theme.textColor};
    transition:
      transform 0.3s ease-in-out,
      opacity 0.3s ease-in-out;
  }

  &:before {
    transform: translateY(${({ isMenuVisible }) => (isMenuVisible ? "0" : "-5px")})
      rotate(${({ isMenuVisible }) => (isMenuVisible ? "90deg" : "0deg")});
  }

  &:after {
    transform: translateY(${({ isMenuVisible }) => (isMenuVisible ? "0" : "5px")})
      rotate(${({ isMenuVisible }) => (isMenuVisible ? "180deg" : "0deg")});
  }
`;
