import { makeAutoObservable } from "mobx";
import { getChainsMeta } from "src/api/bots/DEX_NE/chains";
import { makeLoggable } from "src/helpers/logger";
import { IDisposable } from "src/helpers/utils";
import { chainsMetaRespToChainMeta } from "./mappers";
import { ChainMetaMap } from "./types";

export interface IChainsMetaProvider {
  get chainMetaMap(): ChainMetaMap;
  getChainMeta: () => Promise<void>;
}

export class ChainsMetaStore implements IChainsMetaProvider, IDisposable {
  private _chainMeta: ChainMetaMap | null = null;

  private _loading = false;

  constructor() {
    makeAutoObservable(this);

    makeLoggable(this, { chainMetaMap: true });
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  get chainMetaMap() {
    return this._chainMeta ?? {};
  }

  private _setChainMeta = (meta: ChainMetaMap) => {
    this._chainMeta = meta;
  };

  getChainMeta = async () => {
    await this._getChainsMeta();
  };

  private _getChainsMeta = async () => {
    if (this._loading || this._chainMeta) return;

    this._setLoading(true);
    try {
      const { isError, data } = await getChainsMeta();
      if (!isError) {
        const chainConfigs = chainsMetaRespToChainMeta(data);
        this._setChainMeta(chainConfigs);
      }
    } finally {
      this._setLoading(false);
    }
  };

  destroy = () => {};
}
