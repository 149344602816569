import { Token } from "@uniswap/sdk-core";
import { ADDRESS_ZERO } from "./constants";

interface AbstractTokenParams {
  chainId?: number;
  address?: string;
  decimals?: number;
  symbol?: string;
  name?: string;
}

export class AbstractToken extends Token {
  constructor({ chainId, address, decimals, symbol, name }: AbstractTokenParams) {
    super(
      chainId ?? 1,
      address ?? ADDRESS_ZERO,
      decimals ?? 18,
      symbol ?? "TOKEN",
      name ?? "Abstract Token"
    );
  }
}
