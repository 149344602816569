import { makeAutoObservable } from "mobx";
import { Nullish, isDefined } from "src/helpers/utils";

type BaseStrategy = {
  status: boolean;
};

export interface IStrategiesProvider<T extends BaseStrategy> {
  get strategies(): T[];
  setStrategies: (strategies: T[]) => void;
  saveStrategies: () => Promise<boolean>;
}

export interface IStrategies<T extends BaseStrategy> {
  updateStrategy: (strategy: T) => Promise<boolean>;
  deleteStrategy: (index: number) => Promise<boolean>;
  toggleActiveStrategy: (index: number) => Promise<boolean>;
  setSelectedStrategy: (index: Nullish<number>) => void;
  get selectedStrategy(): T | null;
}

export class StrategiesStore<T extends BaseStrategy> implements IStrategies<T> {
  private _strategiesProvider: IStrategiesProvider<T>;

  private _selectedStrategyIndex: Nullish<number> = null;

  constructor(strategiesProvider: IStrategiesProvider<T>) {
    makeAutoObservable(this);

    this._strategiesProvider = strategiesProvider;
  }

  private get _counterStrategies() {
    return this._strategiesProvider.strategies;
  }

  private _setCounterStrategies = (strategies: T[]) => {
    this._strategiesProvider.setStrategies(strategies);
  };

  private _saveCounterStrategies = async () => await this._strategiesProvider.saveStrategies();

  private _getCounterStrategy = (index: Nullish<number>): T | null => {
    const strategies = this._counterStrategies;
    if (!isDefined(index) || index >= strategies.length) return null;
    return strategies[index] ?? null;
  };

  private _addCounterStrategy = (strategy: T) => {
    const newStrategies = [...this._counterStrategies, strategy];
    this._setCounterStrategies(newStrategies);
  };

  private _updateCounterStrategy = (strategy: T, index: number) => {
    const newStrategies = this._counterStrategies.map((currentStrategy, i) =>
      i === index ? strategy : currentStrategy
    );
    this._setCounterStrategies(newStrategies);
  };

  private _deleteCounterStrategy = (index: number) => {
    const newStrategies = this._counterStrategies.filter((_, i) => i !== index);
    this._setCounterStrategies(newStrategies);
  };

  private _updateCounterStrategies = (strategy?: T, index?: Nullish<number>) => {
    if (isDefined(index)) {
      if (strategy) {
        this._updateCounterStrategy(strategy, index);
      } else {
        this._deleteCounterStrategy(index);
      }
    } else if (strategy) {
      this._addCounterStrategy(strategy);
    }
  };

  updateStrategy = async (strategy: T) => {
    const strategyIndex = this._selectedStrategyIndex;
    this._updateCounterStrategies(strategy, strategyIndex);

    return await this._saveCounterStrategies();
  };

  private _toggleActiveCounterStrategy = (index: Nullish<number>) => {
    const currentStrategy = this._getCounterStrategy(index);
    if (!currentStrategy || !isDefined(index)) return;

    const newStrategy = { ...currentStrategy, status: !currentStrategy.status };
    this._updateCounterStrategy(newStrategy, index);
  };

  toggleActiveStrategy = async (index: number) => {
    this._toggleActiveCounterStrategy(index);

    return await this._saveCounterStrategies();
  };

  deleteStrategy = async (index: number) => {
    this._updateCounterStrategies(undefined, index);

    return await this._saveCounterStrategies();
  };

  setSelectedStrategy = (index: Nullish<number>) => {
    this._selectedStrategyIndex = index;
  };

  get selectedStrategy() {
    const selectedIndex = this._selectedStrategyIndex;
    const strategy = this._getCounterStrategy(selectedIndex);
    return strategy;
  }
}
