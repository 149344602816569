import { ApiResponse } from "src/modules/network";
import { IDEXNEBotDTO } from "src/state/DEX_NE/bots";
import { generateMockBot } from "./mocks";

export interface GetBotStatusResponse extends IDEXNEBotDTO {}

export const getBotStatus = async (
  botId: string
): Promise<ApiResponse<GetBotStatusResponse[]>> => ({
  isError: false,
  data: [generateMockBot(botId)],
});
