import { ApiModules, apiUrl } from "src/api";
import { StartCEXBot } from "src/modules/bots";
import { PairDecimalsResponse } from "src/modules/settings";
import ResponseHandler from "src/state/network/ResponseHandler";

export const createBot = async (data: StartCEXBot, party: string, startConfig: boolean) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Parties, `${party}/bot?start=${startConfig}`),
    method: "POST",
    data,
  });

export const getBotSettings = async (botName: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Bot, `${botName}/settings`),
    method: "GET",
  });

export const setBotSettings = async (bot_uuid: string, data: any) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Bot, `${bot_uuid}/settings`),
    method: "PUT",
    data,
  });

export const getDecimals = async (account_uuid: string, pair: string, exchange: string) =>
  ResponseHandler.handler<PairDecimalsResponse>({
    url: apiUrl(ApiModules.Terminal, "get-decimals"),
    method: "POST",
    data: {
      account_uuid,
      pair,
      exchange,
    },
  });

export const getConstraints = async (bot_uuid: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Bot, `/${bot_uuid}/rules`),
    method: "GET",
  });

export const getSpreadPeriods = async (botName: string, period: number) => {
  const stopPoint = Math.round(Date.now() / 1000);

  return ResponseHandler.handler({
    url: apiUrl(
      ApiModules.Bot,
      `${botName}/spread-decompressions?start=${stopPoint - period}&stop=${stopPoint}`
    ),
    method: "GET",
  });
};

export const getVerifyPeriods = async (botName: string, period: number) => {
  const stopPoint = Math.round(Date.now() / 1000);

  return ResponseHandler.handler({
    url: apiUrl(
      ApiModules.Bot,
      `${botName}/verify-orders?start=${stopPoint - period}&stop=${stopPoint}`
    ),
    method: "GET",
  });
};

export const startBot = async (botName: string): Promise<any> =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Bot, `${botName}/start`),
    method: "POST",
  });

export const stopBot = async (botName: string): Promise<any> =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Bot, `${botName}/stop`),
    method: "POST",
  });
