import { hexToRgb } from "src/helpers/colors";
import { PrimaryColor, getPrimaryColor } from "src/helpers/theme";
import { readonlyArrToObj } from "src/helpers/utils";

export const BASE_COLOR_V2 = {
  BACKGROUND_SURFACE: "#F5F7FB",
};

const primaryColor = getPrimaryColor();

const PRIMARY_BLUE_COLORS = {
  PRIMARY: "#156CE3",
  PRIMARY_LIGHT: "#328EE3",
  BACKGROUND_PROMPT: "#F1F6FD",
};

const PRIMARY_ORANGE_COLORS = {
  PRIMARY: "#FF6B00",
  PRIMARY_LIGHT: "#ff8933",
  BACKGROUND_PROMPT: "#FFF4ED",
};

const getBasePrimaryColors = (color: PrimaryColor) => {
  switch (color) {
    case "blue": {
      return PRIMARY_BLUE_COLORS;
    }
    case "orange": {
      return PRIMARY_ORANGE_COLORS;
    }
  }
};

const getPrimaryButtonsLightStateColors = (baseColors: BaseColors, color: PrimaryColor) => {
  switch (color) {
    case "blue": {
      return {
        primary: {
          enabled: baseColors.PRIMARY,
          hover: "#2D7DEA",
          focused: "#0D5BC6",
          active: "#0B4799",
        },
        secondary: {
          enabled: "#D7E7FE",
          hover: "#EBF3FF",
          focused: "#a5c9fd",
          active: "#73abfc",
        },
      };
    }
    case "orange": {
      return {
        primary: {
          enabled: baseColors.PRIMARY,
          hover: "#ff7919",
          focused: "#c50",
          active: "#b24a00",
        },
        secondary: {
          enabled: "#ffdbc0",
          hover: "#ffe2cc",
          focused: "#ccaf99",
          active: "#b29986",
        },
      };
    }
  }
};

const getPrimaryButtonsDarkStateColors = (baseColors: BaseColors, color: PrimaryColor) => {
  switch (color) {
    case "blue": {
      return {
        primary: {
          enabled: baseColors.PRIMARY,
          hover: "#2D7DEA",
          focused: "#0D5BC6",
          active: "#0B4799",
        },
        secondary: {
          enabled: "#5c88c9",
          hover: "#6799e2",
          focused: "#5077b0",
          active: "#456697",
        },
      };
    }
    case "orange": {
      return {
        primary: {
          enabled: baseColors.PRIMARY,
          hover: "#ff7919",
          focused: "#c50",
          active: "#b24a00",
        },
        secondary: {
          enabled: "#7f6d60",
          hover: "#998373",
          focused: "#66574c",
          active: "#4c4139",
        },
      };
    }
  }
};

const getPrimaryButtonsBackgroundLightStateColors = (
  baseColors: BaseColors,
  color: PrimaryColor
) => {
  switch (color) {
    case "blue": {
      return {
        primary: {
          enabled: "#F4F9FF",
          hover: "#DFEDFD",
          focused: baseColors.PRIMARY,
          active: "#0B4799",
        },
        secondary: {
          enabled: "#fbfcfe",
          hover: "#f3f7fe",
          focused: "#D7E7FE",
          active: "#73abfc",
        },
      };
    }
    case "orange": {
      return {
        primary: {
          enabled: "#fff4ec",
          hover: "#ffeddf",
          focused: baseColors.PRIMARY,
          active: "#e5c5ac",
        },
        secondary: {
          enabled: "#fff4ec",
          hover: "#fffbf8",
          focused: "#fff0e5",
          active: "#ffeddf",
        },
      };
    }
  }
};

const getPrimaryButtonsBackgroundDarkStateColors = (
  baseColors: BaseColors,
  color: PrimaryColor
) => {
  switch (color) {
    case "blue": {
      return {
        primary: {
          enabled: "#062044",
          hover: "#0a3671",
          focused: "#4389e8",
          active: "#2c7ae5",
        },
        secondary: {
          enabled: "#2e4464",
          hover: "#456697",
          focused: "#D7E7FE",
          active: "#73abfc",
        },
      };
    }
    case "orange": {
      return {
        primary: {
          enabled: "#321500",
          hover: "#662a00",
          focused: "#e56000",
          active: "#c50",
        },
        secondary: {
          enabled: "#4c4139",
          hover: "#66574c",
          focused: "#b29986",
          active: "#998373",
        },
      };
    }
  }
};

const BASE_COLORS = {
  /* Accent / Primary */
  ...getBasePrimaryColors(primaryColor),
  DANGER: "#D84E4E",
  LIGHT_RED: "#F48D76",
  SUCCESS: "#69B40A",
  MEDIUM_GREEN: "#81CF1D",
  YELLOW: "#F2BD01",
  BLUE: "#156CE3",
  BLUE_LIGHT: "#328EE3",
  MEDIUM_YELLOW: "#EEC945",
  PURPLE: "#8A48E1",
  GOLD: "#FFBB1F",
  LIME: "#5CC389",
  DARK_BLUE: "#363C4E",
  LILAC: "#DD22FC",

  /* Background */
  BACKGROUND_WHITE: "#FFFFFF",
  BACKGROUND_LIGHT: "#F6F6F6",
  BACKGROUND_HOVER: "#F4F5F7",
  BACKGROUND_FORM: "#EFEFEF",
  BACKGROUND_GRAY: "#E0E0E0",
  BACKGROUND_HOVER_DARK: "#2D2D2D",
  BACKGROUND_DARK_LIGHT: "#1E1E1E",
  BACKGROUND_DARK: "#171717",

  /* GrayScale */
  WHITE: "#FFFFFF",
  BLACK: "#1A1A1A",
  DARKER_GRAY: "#363636",
  BIG_TEXT: "#3E3E3E",
  DARK_GRAY: "#585858",
  SMALL_TEXT: "#767676",
  GRAY: "#A2A2A2",
  MEDIUM_GRAY: "#A1A1A1",
  BORDER_GRAY: "#BDBDBD",
  LIGHT_GRAY: "#D6D6D6",
  LIGHTER_GRAY: "#D9D9D9",
};

type BaseColors = typeof BASE_COLORS;

const fontWeights = {
  ...readonlyArrToObj([400, 500, 600, 700] as const),
  normal: 400,
  bold: 700,
} as const;

const fontSizes = {
  ...readonlyArrToObj([
    "8px",
    "9px",
    "10px",
    "11px",
    "12px",
    "13px",
    "14px",
    "15px",
    "16px",
    "18px",
    "19px",
    "20px",
    "22px",
    "24px",
    "25px",
  ] as const),
  heading1: "42px",
  heading2: "20px",
  heading3: "16px",
  heading4: "11px",
  subHeading: "10px",
} as const;

const lineHeights = {
  ...readonlyArrToObj([
    "8px",
    "10px",
    "12px",
    "14px",
    "15px",
    "16px",
    "18px",
    "20px",
    "24px",
    "36px",
  ] as const),
};

const radii = {
  ...readonlyArrToObj([
    "0px",
    "2px",
    "3px",
    "4px",
    "6px",
    "8px",
    "10px",
    "16px",
    "34px",
    "50px",
    "50%",
    "100%",
  ] as const),
};

const borders = {
  solidGray: `1px solid ${BASE_COLORS.SMALL_TEXT}`,
} as const;

const shadows = {
  press: "0px 5px 10px 2px rgba(34, 60, 80, 0.2) inset",
  dashboard: "0px 4px 9px rgba(0, 0, 0, 0.1)",
  switchMenu: "0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04)",
  button: `0px 16px 78px -4px rgba(24, 39, 75, 0.14),
        0px 8px 25px -5px rgba(24, 39, 75, 0.12);`,
} as const;

const dimensions = {
  headerHeight: "62px",
} as const;

export type ButtonState = "active" | "hover" | "enabled" | "focused";
export type ButtonColor = "primary" | "secondary" | "danger" | "success" | "neutral";

const BUTTON_BACKGROUND_STATE_LIGHT: Record<ButtonColor, Record<ButtonState, string>> = {
  ...getPrimaryButtonsLightStateColors(BASE_COLORS, primaryColor),
  danger: {
    enabled: BASE_COLORS.DANGER,
    hover: "#df7171",
    focused: "#BE3A3A",
    active: "#962929",
  },
  success: {
    enabled: BASE_COLORS.SUCCESS,
    hover: "#87c33b",
    focused: "#62A40E",
    active: "#51850E",
  },
  neutral: {
    enabled: BASE_COLORS.LIGHT_GRAY,
    hover: "#dedede",
    focused: "#bdbdbd",
    active: "#a3a3a3",
  },
};

const BUTTON_BACKGROUND_STATE_DARK: Record<ButtonColor, Record<ButtonState, string>> = {
  ...getPrimaryButtonsDarkStateColors(BASE_COLORS, primaryColor),
  danger: {
    enabled: BASE_COLORS.DANGER,
    hover: "#df7171",
    focused: "#BE3A3A",
    active: "#962929",
  },
  success: {
    enabled: BASE_COLORS.SUCCESS,
    hover: "#87c33b",
    focused: "#62A40E",
    active: "#51850E",
  },
  neutral: {
    enabled: "#828282",
    hover: "#929292",
    focused: "#727272",
    active: "#616161",
  },
};

const BUTTON_LIGHT_BACKGROUND_LIGHT: Record<ButtonColor, Record<ButtonState, string>> = {
  ...getPrimaryButtonsBackgroundLightStateColors(BASE_COLORS, primaryColor),
  danger: {
    enabled: "#fbeded",
    hover: "#f3c9c9",
    focused: BASE_COLORS.DANGER,
    active: "#962929",
  },
  success: {
    enabled: "#f0f7e6",
    hover: "#d2e8b5",
    focused: BASE_COLORS.SUCCESS,
    active: "#51850E",
  },
  neutral: {
    enabled: "#fafafa",
    hover: "#f2f2f2",
    focused: BASE_COLORS.LIGHT_GRAY,
    active: "#a3a3a3",
  },
};

const BUTTON_LIGHT_BACKGROUND_DARK: Record<ButtonColor, Record<ButtonState, string>> = {
  ...getPrimaryButtonsBackgroundDarkStateColors(BASE_COLORS, primaryColor),
  danger: {
    enabled: "#401717",
    hover: "#6c2727",
    focused: BASE_COLORS.DANGER,
    active: "#962929",
  },
  success: {
    enabled: "#345a05",
    hover: "#3f6c06",
    focused: BASE_COLORS.SUCCESS,
    active: "#51850E",
  },
  neutral: {
    enabled: "#404040",
    hover: "#6b6b6b",
    focused: BASE_COLORS.LIGHT_GRAY,
    active: "#a3a3a3",
  },
};

export const BASE_THEME = {
  fontWeights,
  radii,
  fontSizes,
  lineHeights,
  borders,
  shadows,
  dimensions,
} as const;

export type BaseTheme = typeof BASE_THEME;

export type Theme = typeof lightTheme;
export type ThemeMode = "light" | "dark";
export type BotStatusColors = keyof typeof lightTheme.botBgColorStatus;

const lightDashboard = {
  textSubhead: "#585858",
  textSecondary: "#99A2AD",
  textHeading: "#1A1A1A",
  textDefault: "#3A3A3A",
  textAdditional: "#464852",
  borderDefault: "#DFE1E9",
  backgroundOverlay: "#F4F4F5",
  backgroundTableHead: "#F7F7F8",
  backgroundSurface: "#F5F7FB",
  backgroundBaseDefault: "#FFFFFF",
  backgroundBaseContrast: "#EBECF1",
  backgroundBaseField: "#FCFCFC",
  backgroundLightGreen: "#E0FCD4",
  backgroundLightRed: "#FFE5E9",
  accent: {
    royalBlue: "#304FFE",
    yellow: "#FDD835",
    green: "#2E7D32",
    cyan: "#00BCD4",
    lightBlue: "#42A5F5",
    deepBlue: "#283593",
    purple: "#D500F9",
    pink: "#D81B60",
    emerald: "#00796B",
    neonGreen: "#C6FF00",
    orange: "#FF8A65",
    darkLime: "#69B40A",
    malachite: "#0EB51E",
    rubyRed: "#ee2222",
    coralPink: "#F48D76",
    dodgerBlue: "#328EE3",
    lavender: "#9CA5E7",
    lightTeal: "#94DFD7",
  },
};

const darkDashboard = {
  textSubhead: "#8b8b8b",
  textSecondary: "#d2d6da",
  textHeading: "#ccc",
  textDefault: "#d3d3d3",
  textAdditional: "#acaeb8",
  borderDefault: "#525977",
  backgroundOverlay: "#27272a",
  backgroundTableHead: "#40404a",
  backgroundSurface: "#19263f",
  backgroundBaseDefault: "#191919",
  backgroundBaseContrast: "#1c1e28",
  backgroundBaseField: "#303030",
  backgroundLightGreen: "#192911",
  backgroundLightRed: "#2F1118",
  accent: {
    royalBlue: "#304FFE",
    yellow: "#FDD835",
    green: "#2E7D32",
    cyan: "#00BCD4",
    lightBlue: "#42A5F5",
    deepBlue: "#283593",
    purple: "#D500F9",
    pink: "#D81B60",
    emerald: "#00796B",
    neonGreen: "#C6FF00",
    orange: "#FF8A65",
    darkLime: "#69B40A",
    malachite: "#0EB51E",
    rubyRed: "#ee2222",
    coralPink: "#F48D76",
    dodgerBlue: "#328EE3",
    lavender: "#9CA5E7",
    lightTeal: "#94DFD7",
  },
};

export const lightTheme = {
  ...BASE_THEME,
  dashboard: lightDashboard,
  buttonBackgroundStateColors: BUTTON_BACKGROUND_STATE_LIGHT,
  buttonLightBackgroundStateColors: BUTTON_LIGHT_BACKGROUND_LIGHT,

  textColor: BASE_COLORS.BLACK,
  textColorBlack: BASE_COLORS.BLACK,
  bigTextColor: BASE_COLORS.BIG_TEXT,

  contentBackgroundColor: BASE_COLORS.BACKGROUND_WHITE,
  mainBackgroundColor: BASE_COLORS.BACKGROUND_LIGHT,
  backgroundWhiteColor: BASE_COLORS.BACKGROUND_WHITE,

  primaryColor: BASE_COLORS.PRIMARY,
  primaryLightColor: BASE_COLORS.PRIMARY_LIGHT,
  dangerColor: BASE_COLORS.DANGER,
  successColor: BASE_COLORS.SUCCESS,

  tradingViewGridColor: BASE_COLORS.LIGHT_GRAY,

  botColorStatus: {
    purple: BASE_COLORS.PURPLE,
    yellow: BASE_COLORS.YELLOW,
    blue: BASE_COLORS.BLUE_LIGHT,
    green: BASE_COLORS.SUCCESS,
    lightRed: BASE_COLORS.LIGHT_RED,
    darkRed: BASE_COLORS.DANGER,
    red: BASE_COLORS.DANGER,
    gray: BASE_COLORS.GRAY,
  },

  botBgColorStatus: {
    purple: hexToRgb(BASE_COLORS.PURPLE, 0.06),
    yellow: hexToRgb(BASE_COLORS.YELLOW, 0.06),
    blue: hexToRgb(BASE_COLORS.BLUE_LIGHT, 0.06),
    green: hexToRgb(BASE_COLORS.MEDIUM_GREEN, 0.06),
    lightRed: hexToRgb(BASE_COLORS.LIGHT_RED, 0.06),
    darkRed: hexToRgb(BASE_COLORS.DANGER, 0.06),
    red: hexToRgb(BASE_COLORS.DANGER, 0.06),
    gray: hexToRgb(BASE_COLORS.GRAY, 0.06),
  },

  botBgSideColorStatus: {
    purple: BASE_COLORS.PURPLE,
    yellow: hexToRgb(BASE_COLORS.YELLOW, 0.2),
    blue: hexToRgb(BASE_COLORS.BLUE_LIGHT, 0.2),
    green: hexToRgb(BASE_COLORS.MEDIUM_GREEN, 0.2),
    lightRed: hexToRgb(BASE_COLORS.LIGHT_RED, 0.2),
    darkRed: hexToRgb(BASE_COLORS.DANGER, 0.2),
    red: hexToRgb(BASE_COLORS.DANGER, 0.2),
    gray: hexToRgb(BASE_COLORS.GRAY, 0.2),
  },

  assetColor: BASE_COLORS.LIME,
  tokenColor: BASE_COLORS.GOLD,

  // bg для селектов
  pathToServerBg: BASE_COLORS.PRIMARY,

  bgGrayBlack: BASE_COLORS.BACKGROUND_FORM,

  colorDowntimeAllBots: BASE_COLORS.PRIMARY,

  // Disabled select bg
  selectDisabledBg: BASE_COLORS.BACKGROUND_FORM,

  // ReadOnly inputs
  disableInputBgColor: BASE_COLORS.BACKGROUND_FORM,
  disableInputColor: BASE_COLORS.GRAY,

  // Error theme for inputs label
  errorLabelColor: BASE_COLORS.DANGER,

  blueGray: BASE_COLORS.PRIMARY,
  exchangeTableColorText: BASE_COLORS.BLACK,
  labelColor: BASE_COLORS.SMALL_TEXT,
  // Serch inputs
  searchInputBgColor: BASE_COLORS.BACKGROUND_FORM,

  // Link color text
  linkColorText: BASE_COLORS.PRIMARY,

  // Close button backGround
  closeBtnBg: BASE_COLORS.LIGHT_GRAY,

  // Bg for loader container
  loaderBg: hexToRgb(BASE_COLORS.BACKGROUND_LIGHT, 0.7),

  // Header border
  headerBorder: BASE_COLORS.BACKGROUND_FORM,

  smallHeader: BASE_COLORS.SMALL_TEXT,

  darkGray: BASE_COLORS.DARK_GRAY,

  bigHeader: BASE_COLORS.BLACK,

  bgSettingsPnl: BASE_COLORS.BACKGROUND_WHITE,

  lightGray: BASE_COLORS.GRAY,

  promptIconBg: hexToRgb(BASE_COLORS.PRIMARY, 0.13),

  inputTextColor: BASE_COLORS.DARK_GRAY,
  inputHoverBgColor: BASE_COLORS.BACKGROUND_HOVER,
  inputBorderColor: BASE_COLORS.BORDER_GRAY,

  checkBoxBg: BASE_COLORS.LIGHTER_GRAY,

  lineColor: BASE_COLORS.BACKGROUND_GRAY,

  promptMsgBg: BASE_COLORS.BACKGROUND_PROMPT,

  selectMenuBg: BASE_COLORS.BACKGROUND_WHITE,

  focusSelectText: BASE_COLORS.DARK_GRAY,

  lockTime: BASE_COLORS.DARK_GRAY,

  svgColor: BASE_COLORS.BLACK,

  totalSvg: BASE_COLORS.BACKGROUND_WHITE,

  gridChartColor: BASE_COLORS.BACKGROUND_GRAY,

  headerColor: BASE_COLORS.BIG_TEXT,

  accent: BASE_COLORS.LILAC,

  // new config at the redesign stage
  newConfig: {
    surface: BASE_COLOR_V2.BACKGROUND_SURFACE,
  },

  mode: "light" as ThemeMode,
  primaryMode: primaryColor,
};

export const darkTheme: Theme = {
  ...BASE_THEME,
  dashboard: darkDashboard,
  buttonBackgroundStateColors: BUTTON_BACKGROUND_STATE_DARK,
  buttonLightBackgroundStateColors: BUTTON_LIGHT_BACKGROUND_DARK,

  textColor: BASE_COLORS.BACKGROUND_WHITE,
  textColorBlack: BASE_COLORS.BLACK,
  bigTextColor: BASE_COLORS.BACKGROUND_WHITE,

  mainBackgroundColor: BASE_COLORS.BACKGROUND_DARK,
  contentBackgroundColor: BASE_COLORS.BACKGROUND_DARK_LIGHT,
  backgroundWhiteColor: BASE_COLORS.BACKGROUND_WHITE,

  primaryColor: BASE_COLORS.PRIMARY,
  primaryLightColor: BASE_COLORS.PRIMARY_LIGHT,

  dangerColor: BASE_COLORS.DANGER,
  successColor: BASE_COLORS.SUCCESS,

  tradingViewGridColor: BASE_COLORS.DARKER_GRAY,

  botColorStatus: {
    purple: BASE_COLORS.PURPLE,
    yellow: BASE_COLORS.YELLOW,
    blue: BASE_COLORS.BLUE_LIGHT,
    green: BASE_COLORS.SUCCESS,
    lightRed: BASE_COLORS.LIGHT_RED,
    darkRed: BASE_COLORS.DANGER,
    red: BASE_COLORS.DANGER,
    gray: BASE_COLORS.GRAY,
  },

  botBgColorStatus: {
    purple: hexToRgb(BASE_COLORS.PURPLE, 0.1),
    yellow: hexToRgb(BASE_COLORS.YELLOW, 0.1),
    blue: hexToRgb(BASE_COLORS.BLUE_LIGHT, 0.1),
    green: hexToRgb(BASE_COLORS.MEDIUM_GREEN, 0.1),
    lightRed: hexToRgb(BASE_COLORS.LIGHT_RED, 0.1),
    darkRed: hexToRgb(BASE_COLORS.DANGER, 0.1),
    red: hexToRgb(BASE_COLORS.DANGER, 0.1),
    gray: hexToRgb(BASE_COLORS.GRAY, 0.1),
  },

  botBgSideColorStatus: {
    purple: BASE_COLORS.PURPLE,
    yellow: hexToRgb(BASE_COLORS.YELLOW, 0.2),
    blue: hexToRgb(BASE_COLORS.BLUE_LIGHT, 0.2),
    green: hexToRgb(BASE_COLORS.MEDIUM_GREEN, 0.2),
    lightRed: hexToRgb(BASE_COLORS.LIGHT_RED, 0.2),
    darkRed: hexToRgb(BASE_COLORS.DANGER, 0.2),
    red: hexToRgb(BASE_COLORS.DANGER, 0.2),
    gray: hexToRgb(BASE_COLORS.GRAY, 0.2),
  },

  assetColor: BASE_COLORS.LIME,
  tokenColor: BASE_COLORS.GOLD,

  // bg для селектов
  pathToServerBg: BASE_COLORS.BACKGROUND_DARK_LIGHT,

  bgGrayBlack: BASE_COLORS.DARKER_GRAY,

  colorDowntimeAllBots: BASE_COLORS.BACKGROUND_WHITE,

  // Disabled select bg
  selectDisabledBg: BASE_COLORS.SMALL_TEXT,

  // ReadOnly inputs
  disableInputBgColor: BASE_COLORS.SMALL_TEXT,
  disableInputColor: BASE_COLORS.LIGHT_GRAY,

  // Error theme for inputs label
  errorLabelColor: BASE_COLORS.DANGER,

  blueGray: BASE_COLORS.BACKGROUND_FORM,
  exchangeTableColorText: BASE_COLORS.GRAY,
  labelColor: BASE_COLORS.GRAY,
  // Serch inputs
  searchInputBgColor: BASE_COLORS.DARKER_GRAY,

  // Link color text
  linkColorText: BASE_COLORS.PRIMARY,

  // Close button backGround
  closeBtnBg: BASE_COLORS.DARKER_GRAY,

  // Bg for loader container
  loaderBg: hexToRgb(BASE_COLORS.BLACK, 0.7),

  // Header border
  headerBorder: BASE_COLORS.SMALL_TEXT,

  smallHeader: BASE_COLORS.SMALL_TEXT,

  darkGray: BASE_COLORS.DARK_GRAY,

  bigHeader: BASE_COLORS.BACKGROUND_WHITE,

  bgSettingsPnl: BASE_COLORS.BACKGROUND_DARK_LIGHT,

  lightGray: BASE_COLORS.GRAY,

  promptIconBg: hexToRgb(BASE_COLORS.PRIMARY, 0.13),

  inputTextColor: BASE_COLORS.BACKGROUND_FORM,
  inputHoverBgColor: BASE_COLORS.BACKGROUND_HOVER_DARK,
  inputBorderColor: BASE_COLORS.BORDER_GRAY,

  checkBoxBg: BASE_COLORS.DARKER_GRAY,

  lineColor: BASE_COLORS.DARKER_GRAY,

  promptMsgBg: BASE_COLORS.DARKER_GRAY,

  selectMenuBg: BASE_COLORS.DARKER_GRAY,

  focusSelectText: BASE_COLORS.DARK_GRAY,

  lockTime: BASE_COLORS.BACKGROUND_FORM,

  svgColor: BASE_COLORS.BACKGROUND_FORM,

  totalSvg: BASE_COLORS.BLACK,

  gridChartColor: BASE_COLORS.DARK_BLUE,

  headerColor: BASE_COLORS.WHITE,

  accent: BASE_COLORS.LILAC,

  // new config at the redesign stage
  newConfig: {
    surface: BASE_COLOR_V2.BACKGROUND_SURFACE,
  },

  mode: "dark" as ThemeMode,
  primaryMode: primaryColor,
};
