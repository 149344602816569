import { makeAutoObservable } from "mobx";
import { IStrategiesProvider } from "src/state/shared/DEX/StrategiesStore";
import { ILimitStrategiesBinder } from "../../shared/LimitStrategiesBinder";
import { DEXNELimitStrategy } from "../../shared/types";

export class LimitStrategiesProvider implements IStrategiesProvider<DEXNELimitStrategy> {
  private _binder: ILimitStrategiesBinder;

  constructor(binder: ILimitStrategiesBinder) {
    makeAutoObservable(this);

    this._binder = binder;
  }

  get strategies() {
    return this._binder.strategies;
  }

  setStrategies = (strategies: DEXNELimitStrategy[]) => {
    this._binder.setStrategies(strategies);
  };

  saveStrategies = async () => true;
}
