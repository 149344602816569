const HTTPS_PROTOCOL = "https://";
const WS_PROTOCOL = "wss://";

const baseUrl = import.meta.env.VITE_APP_API_URL;

export const apiUrl = HTTPS_PROTOCOL + baseUrl;

export const wsUrl = WS_PROTOCOL + baseUrl;

export const googleClientId = import.meta.env.VITE_APP_GOOGLE_CLIENT_ID;

export const isDevEnv = () => !import.meta.env.MODE || import.meta.env.MODE === "development";

export const enableStories = import.meta.env.VITE_APP_ENABLE_STORIES;
