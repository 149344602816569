import { ColorStatusKey, getTheme } from "src/helpers/getStatus/getStatusColor";
import { LiquidityGridStatus } from "src/modules/liquidityGrid";
import { ExchangeModuleStatus } from "src/state/CEX/multiGrid/AddModuleModal";
import { MultiGridStatus } from "src/state/CEX/shared/types";

const gridColorMap: Record<LiquidityGridStatus, ColorStatusKey> = {
  active: "green",
  stopped: "gray",
  initial: "gray",
  failed: "darkRed",
  warning: "yellow",
};

const multiGridStatusMap: Record<MultiGridStatus, ColorStatusKey> = {
  active: "green",
  stopped: "gray",
  initial: "gray",
  failed: "darkRed",
  waiting: "yellow",
};

const exchangeModuleStatusMap: Record<ExchangeModuleStatus, ColorStatusKey> = {
  active: "green",
  stopped: "gray",
  failed: "darkRed",
};

const statusMap = {
  grid: gridColorMap,
  multigrid: multiGridStatusMap,
  exchangeModule: exchangeModuleStatusMap,
};

type StatusMapType = typeof statusMap;
type StatusMapKey = keyof StatusMapType;

export const getStatusColor = <T extends StatusMapKey>(type: T, status: keyof StatusMapType[T]) => {
  const { botColorStatus } = getTheme();
  const colorName = statusMap[type][status] as ColorStatusKey;

  if (!status || !colorName) return botColorStatus.gray;

  return botColorStatus[colorName];
};
