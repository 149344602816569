import { makeAutoObservable, runInAction } from "mobx";
import { getPartyAccounts } from "src/api/userManager/partiesAPI";
import { getSelectorList } from "src/helpers/forms/selectors";
import { logError } from "src/helpers/network/logger";
import { Account } from "src/modules/userManager";

interface IUseAccounts {
  party: string;
}

class AccountManagerStore {
  private _accounts: Record<string, Account[]> = {};

  mainState: IUseAccounts;

  constructor(state: IUseAccounts) {
    this.mainState = state;

    makeAutoObservable(this);
  }

  get exchanges() {
    return Object.keys(this._accounts);
  }

  get exchOptions() {
    return getSelectorList(this.exchanges);
  }

  get accountsMap() {
    const hashMap: Record<string, Account> = {};

    for (const key of Object.keys(this._accounts)) {
      if (Array.isArray(this._accounts[key])) {
        for (const el of this._accounts[key]) {
          hashMap[el.uuid] = el;
        }
      }
    }

    return hashMap;
  }

  get accounts() {
    return this._accounts;
  }

  getSelectAccounts = (id: string) => {
    const exchAccounts = this._accounts[id];

    if (exchAccounts)
      return exchAccounts.map(({ name, uuid }) => ({
        value: uuid,
        label: name,
      }));

    return [];
  };

  getSelectAccount = (id: string, accountUUID: string) => {
    const exchAccounts = this._accounts[id];

    if (!exchAccounts) return null;

    const account = exchAccounts.find((el) => el.uuid === accountUUID);

    if (account) return { value: accountUUID, label: account.name };

    return null;
  };

  loadAccounts = async () => {
    this._getAccounts(this.mainState.party);
  };

  private _getAccounts = async (party: string) => {
    try {
      const { data, isError } = await getPartyAccounts(party, false);

      if (!isError) {
        runInAction(() => {
          this._accounts = data;
        });
      }
    } catch (err) {
      logError(err);
    }
  };

  destroy = () => {};
}

export default AccountManagerStore;
