import { ApiResponse } from "src/modules/network";
import { DEXCommonExchangeVersion } from "src/modules/bots";
import { CHAINS_META_MOCK, SUPPORTED_CHAINS_MOCK, SUPPORTED_EXCHANGES_MOCK } from "./mocks";

export interface SupportedChain {
  scanner: string;
  symb: string;
  chain_name: string;
  dexscreener_alias: string;
}

export interface GetSupportedChainsResponse {
  chains: SupportedChain[];
}

export const getSupportedChains = async (): Promise<ApiResponse<GetSupportedChainsResponse>> => ({
  isError: false,
  data: SUPPORTED_CHAINS_MOCK,
});

export interface SupportedExchange {
  dex_version: DEXCommonExchangeVersion;
  pool_percent: number[];
}

export type SupportedExchanges = Record<string, Record<string, SupportedExchange>>;

export interface GetSupportedExchangesResponse extends SupportedExchanges {}

export const getSupportedExchanges = async (): Promise<
  ApiResponse<GetSupportedExchangesResponse>
> => ({
  isError: false,
  data: SUPPORTED_EXCHANGES_MOCK,
});

export interface ChainsMetaResponse {
  chain: string;
  abbr: string;
  colour: string;
  pic: string;
}

export type GetChainsMetaResponse = ChainsMetaResponse[];

export const getChainsMeta = async (): Promise<ApiResponse<GetChainsMetaResponse>> => ({
  isError: false,
  data: CHAINS_META_MOCK,
});
