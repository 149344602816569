import { DEXNEAbility, DEXNERoutes } from "src/app/constants";
import { NavigationTabsIcons } from "src/icons/NavigationTabIcons";
import { BotContentMenuItem } from "..";
import { BotQueryParams } from "../../../shared/MenuList/MenuItem/types";
import { DEXNETabs, getBotLink } from "../../../shared/MenuList/utils";

type DEXNEMenuItem<T extends DEXNETabs = DEXNETabs> = T extends DEXNETabs
  ? BotContentMenuItem<T>
  : never;

const BOT_TYPE = "DEX_NE";

const stats = {
  abilityName: DEXNEAbility.StatsView,
  label: "Stats",
  value: DEXNERoutes.Stats,
  icon: NavigationTabsIcons.Stats,
  to: (pathParam: BotQueryParams) =>
    getBotLink({ botType: BOT_TYPE, botTab: DEXNERoutes.Stats, ...pathParam }),
} satisfies DEXNEMenuItem;

const settings = {
  abilityName: DEXNEAbility.SettingsView,
  label: "Settings",
  value: DEXNERoutes.Settings,
  icon: NavigationTabsIcons.Settings,
  to: (pathParam: BotQueryParams) =>
    getBotLink({ botType: BOT_TYPE, botTab: DEXNERoutes.Settings, ...pathParam }),
} satisfies DEXNEMenuItem;

export const DEXNEMenuItems: DEXNEMenuItem[] = [stats, settings];
