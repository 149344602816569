import styled, { css } from "styled-components";

export interface StatusProps {
  connectStatus: number;
}

interface StatusContainerProps {
  $color?: string;
}

export const Container = styled.div<StatusContainerProps>`
  width: 100px;

  display: flex;
  justify-content: flex-end;

  gap: 4px;

  cursor: pointer;

  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `}
`;

export const StatusTitle = styled.span`
  color: currentColor;
  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  white-space: nowrap;
`;

export const SVGGroup = styled.g<StatusProps>`
  ${({ connectStatus }) => css`
    & > path:nth-of-type(-n + ${connectStatus}) {
      fill: currentColor;
    }
  `}

  & > path {
    fill: #cdced1;
  }
`;
