import { observer } from "mobx-react-lite";
import { CEXAbilities, CEXRoutes } from "src/app/constants";
import { BotActionButton } from "src/components/AllBots/Bots/shared/BotActionButton";
import {
  getBotLink,
  getPartySectionLink,
} from "src/components/NavigationMenu/MenuContent/shared/MenuList/utils";
import { PermissionWrapper } from "src/components/shared/PermissionWrapper";
import { getDowntime } from "src/helpers/dateUtils";
import { useBgColorStatus, useColorStatus } from "src/helpers/getStatus/getStatusColor";
import Icons from "src/icons/Icons";
import { BotMark } from "../../shared/BotMark";
import { BotPairButton } from "../../shared/BotPairButton";
import { DownTime, Favorite, LockTime, MobBotContainer, MobLine, Name } from "../../shared/shared";
import { IBotCredential, copyBotCredential, getFormattedTimeNotWork } from "../../shared/utils";
import { CEXBotProps } from "../CEXBot";
import * as styles from "./style";

export const CEXBotMob = observer(
  ({
    favorite,
    toggleIsWatched,
    status,
    timeDontWork,
    modifyVolume,
    link,
    exchange,
    side,
    message,
    diff,
    modifyTime,
    pair,
    party,
    bot_uuid,
    market,
    name,
    actions,
    isWatched,
    afterContent,
    ...props
  }: CEXBotProps) => {
    const { base, quote, updated_at, created_at, ...containerProps } = props;

    const botColorStatus = useColorStatus();

    const botBgColorStatus = useBgColorStatus();

    const ActionButton = <BotActionButton status={status} actions={actions} />;

    const timeNotWork = getFormattedTimeNotWork(timeDontWork, false);

    const botLinkParams = {
      party,
      uuid: bot_uuid,
    };

    const dashboardLink = getPartySectionLink({ party, partySection: "Dashboard" });

    const credential: IBotCredential = {
      market,
      name,
      UUID: bot_uuid,
      link: dashboardLink,
    };

    return (
      <MobBotContainer {...containerProps}>
        {favorite ? (
          <Favorite onClick={() => toggleIsWatched(bot_uuid, market)}>
            {isWatched ? Icons.startStatusTrue() : Icons.startStatusFalse()}
          </Favorite>
        ) : null}

        <styles.StyledFrame
          color={botColorStatus(status, timeDontWork)}
          style={{ backgroundColor: botBgColorStatus(status, timeDontWork) }}
        >
          <styles.RowWrapper>
            <styles.FirstRow>
              {bot_uuid ? (
                <styles.Wrapper>
                  <BotPairButton
                    to={dashboardLink}
                    color={botColorStatus(status, timeDontWork)}
                    style={{ height: "25px" }}
                  >
                    {pair?.split("_").join(" _ ")}
                  </BotPairButton>
                  <Name>{name}</Name>
                </styles.Wrapper>
              ) : null}

              <styles.MobExchange href={link || "#"} target="_blank">
                {exchange}
              </styles.MobExchange>

              <DownTime
                style={{
                  height: "100%",
                  width: "100%",
                }}
              >
                <styles.MobTime>{timeNotWork}</styles.MobTime>
              </DownTime>

              {modifyVolume && getDowntime(modifyTime) ? (
                <LockTime
                  style={{
                    height: "100%",
                  }}
                >
                  {Icons.lock()}{" "}
                  <styles.MobLockTime>
                    {modifyVolume && getDowntime(modifyTime, false)}
                  </styles.MobLockTime>
                </LockTime>
              ) : null}

              <styles.WrapperForBtn>
                {bot_uuid ? (
                  <styles.MobExchangeLink
                    to={getBotLink({
                      botType: "CEX",
                      botTab: CEXRoutes.Exchange,
                      ...botLinkParams,
                    })}
                    color={botColorStatus(status, timeDontWork)}
                  >
                    {side}
                  </styles.MobExchangeLink>
                ) : null}

                {ActionButton ? (
                  <PermissionWrapper abilityName={CEXAbilities.BotManage}>
                    {ActionButton}
                  </PermissionWrapper>
                ) : (
                  <BotMark
                    onClick={() => copyBotCredential(credential)}
                    color={botColorStatus(status, timeDontWork)}
                  />
                )}
              </styles.WrapperForBtn>
            </styles.FirstRow>

            <styles.SecondRow>
              {diff ? <styles.MobComment>{diff}</styles.MobComment> : <MobLine />}

              {message ? <styles.MobMessage>{message}</styles.MobMessage> : <MobLine />}
            </styles.SecondRow>
          </styles.RowWrapper>

          {afterContent}
        </styles.StyledFrame>
      </MobBotContainer>
    );
  }
);
