import { formatPercent } from "src/helpers/string";
import { ChainInfo } from "src/state/chain/ChainsInfo/Chains/types";
import { ScannerLinkType } from "./types";

export const getScannerLink = (info: ChainInfo | null, type: ScannerLinkType) => {
  if (!info) return undefined;
  const { addressScanner, txScanner } = info;
  switch (type) {
    case "tx": {
      return txScanner;
    }
    case "wallet": {
      return addressScanner;
    }
  }
};
export const poolPercentToBips = (poolPercent: number) => poolPercent * 10000;

export const poolPercentBipsToPercent = (poolPercent: number) => {
  const percent = poolPercent / 10000;
  return percent;
};

export const formatPoolPercent = (poolPercent: number) => {
  const percent = poolPercentBipsToPercent(poolPercent);
  return formatPercent(percent);
};
