import { makeAutoObservable } from "mobx";
import { getBotStatus } from "src/api/bots/DEX_NE/bots";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { IDisposable } from "src/helpers/utils";
import {
  IBotAddressesProvider,
  IBotNameProvider,
  IBotTickersProvider,
  IBotUUIDProvider,
  IPartyProvider,
  IScreenerNetworkProvider,
  ISwapPairAddressProvider,
} from "src/state/shared/DEX/providers/types";
import { IDEXNEChainsInfoProvider } from "../Info";
import { DEXNEBotContextProvider, IDEXNEBotContextProvider } from "../Providers/BotContextProvider";
import { IDEXNECacheProvider } from "../Providers/RootProvider";
import { INITIAL_DEX_NE_BOT } from "./constants";
import { botResponseToDEXNEBot } from "./mapper";
import { IDEXNEBot, IDEXNEChainInfoProvider } from "./types";

interface IDEXNEBotParams {
  cacheProvider: IDEXNECacheProvider;
  chainsInfoProvider: IDEXNEChainsInfoProvider;
  botUUID: string;
  party: string;
}

export class DEXNEBotStore
  implements
    IBotAddressesProvider,
    IBotTickersProvider,
    ISwapPairAddressProvider,
    IScreenerNetworkProvider,
    IBotUUIDProvider,
    IBotNameProvider,
    IPartyProvider,
    IDEXNEChainInfoProvider,
    IDisposable
{
  private _botUUID: string;

  private _party: string;

  private _bot: IDEXNEBot = INITIAL_DEX_NE_BOT;

  private _isLoading: boolean = false;

  private _botContextProvider: IDEXNEBotContextProvider & IDisposable;

  private _cacheProvider: IDEXNECacheProvider;

  private _chainsInfoProvider: IDEXNEChainsInfoProvider;

  constructor({ cacheProvider, chainsInfoProvider, botUUID, party }: IDEXNEBotParams) {
    makeAutoObservable(this);

    this._cacheProvider = cacheProvider;
    this._chainsInfoProvider = chainsInfoProvider;

    this._botContextProvider = new DEXNEBotContextProvider({
      networkProvider: this,
      pairAddressProvider: this,
      pairCacheStore: this._screenerPairCache,
      tickersProvider: this,
    });

    this._party = party;
    this._botUUID = botUUID;

    makeLoggable(this, {
      chainName: true,
      chainInfo: true,
    });
  }

  get botUUID() {
    return this._botUUID;
  }

  get botName() {
    return this._bot.name;
  }

  get party() {
    return this._party;
  }

  private get _chains() {
    return this._chainsInfoProvider.chains;
  }

  get screenerNetwork() {
    const chainInfo = this._chains[this.chainName];

    if (chainInfo) return chainInfo.dexscreenerName;

    return null;
  }

  get chainName() {
    return this._bot.chainName;
  }

  get pairAddress() {
    return this._bot.pairAddr;
  }

  get addresses() {
    const baseAddress = this._bot.baseAddr;
    const quoteAddress = this._bot.quoteAddr;
    if (!quoteAddress || !baseAddress) {
      return null;
    }
    return { quote: quoteAddress, base: baseAddress };
  }

  get chainInfo() {
    const { chainName } = this;
    if (!chainName) return null;

    return this._chains[chainName] ?? null;
  }

  get tickers() {
    const baseTicker = this._bot.base;
    const quoteTicker = this._bot.quote;
    const nativeTicker = this.chainInfo?.native;
    if (!quoteTicker || !baseTicker || !nativeTicker) {
      return null;
    }
    return { quote: quoteTicker, base: baseTicker, native: nativeTicker };
  }

  get isLoading() {
    return this._isLoading;
  }

  get botContextProvider() {
    return this._botContextProvider;
  }

  private get _tradePairProvider() {
    return this._botContextProvider.tradePairProvider;
  }

  private get _screenerPairCache() {
    return this._cacheProvider.screenerPairCache;
  }

  private _setBot = (bot: IDEXNEBot) => {
    this._bot = bot;
  };

  private _setLoading = (load: boolean) => {
    this._isLoading = load;
  };

  private _fetchBot = async () => {
    const { isError, data } = await getBotStatus(this._botUUID);
    if (!isError) {
      const bot = botResponseToDEXNEBot(data[0]);
      this._setBot(bot);
    }
    return isError;
  };

  loadBot = async () => {
    this._setLoading(true);

    try {
      const isError = await this._fetchBot();
      if (!isError) {
        await this._tradePairProvider.getTradePair();
      }
    } catch (err) {
      logError(err);
    } finally {
      this._setLoading(false);
    }
  };

  destroy = () => {
    this._botContextProvider.destroy();
  };
}
