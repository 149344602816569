import { Mapper } from "src/helpers/utils";
import { ChainMetaMap } from "../Info/ChainsMeta/types";
import { getBotColorStatus } from "./utils";
import { IDEXNEBot, IDEXNEBotDTO, IListDEXNEBot } from "./types";

export const botResponseToDEXNEBot: Mapper<IDEXNEBotDTO, IDEXNEBot> = ({
  base_addr,
  is_stopped,
  bot_uuid,
  chain_name,
  pair_addr,
  quote_addr,
  dex_version,

  limit_descr,
  limit_bot_last_trade_status,
  limit_bot_next_trade_date_unix,
  limit_bot_last_trade_date_unix,

  volume_descr,
  volume_bot_last_trade_status,
  volume_bot_next_trade_date_unix,
  volume_bot_last_trade_date_unix,

  ...rest
}) => ({
  baseAddr: base_addr,
  botUUID: bot_uuid,
  chainName: chain_name,
  pairAddr: pair_addr,
  quoteAddr: quote_addr,
  isStopped: is_stopped,
  dexVersion: dex_version,

  limit: {
    status: limit_descr,
    message: limit_bot_last_trade_status,
    nextTrade: limit_bot_next_trade_date_unix,
    lastTrade: limit_bot_last_trade_date_unix,
  },

  volume: {
    status: volume_descr,
    message: volume_bot_last_trade_status,
    nextTrade: volume_bot_next_trade_date_unix,
    lastTrade: volume_bot_last_trade_date_unix,
  },

  ...rest,
});

export const dexNEBotToListDEXNEBot = (
  bot: IDEXNEBot,
  chainMetaMap: ChainMetaMap
): IListDEXNEBot => {
  const { chainName, base, quote, status } = bot;
  const colorStatus = getBotColorStatus(status);

  return {
    ...bot,
    colorStatus,
    pair: `${quote}_${base}`,
    chainMeta: chainMetaMap[chainName] ?? null,
    isWatched: false,
  };
};
